import React from 'react';

const Logo = (props) => {
    return (
        <img
            alt="Logo"
            src="/static/images/newLogo.svg"
            style={{width: '117px', borderRadius: 3, height: '38.92px'}}
            {...props}
        />
    );
}

export default Logo;