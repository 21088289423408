import React from "react"; class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        // Define a state variable to track whether there is an error        
        this.state = { hasError: false, error: null, errorInfo: null, timestamp: null };
    } static getDerivedStateFromError(error) {
        // Update state so the next render will show the fallback UI        
        return { hasError: true };
    }
    componentDidCatch(error, errorInfo) {
        // Log error details       
        console.error("ErrorBoundary caught an error", error, errorInfo);
        // Capture the current timestamp when an error occurs        
        const timestamp = new Date().toLocaleString();
        this.setState({ error, errorInfo, timestamp });
    }
    handleRetry = () => {
        // Reset error state        
        this.setState({ hasError: false, error: null, errorInfo: null, timestamp: null });
    };
    render() {
        // Check if there is an error        
        if (this.state.hasError) {
            return (
                <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100 p-6">
                    <div className="bg-white shadow-lg rounded-lg p-8 max-w-lg w-full text-center">
                        <img src="https://media.giphy.com/media/26xBwdIuRJiAIqHwA/giphy.gif" alt="Error" className="w-48 mx-auto mb-6" />
                        <h2 className="text-2xl font-bold text-red-500 mb-4">
                            Oops, something went wrong!
                        </h2>
                        <p className="text-gray-700 mb-4">
                            An unexpected error has occurred. Please try again or contact support if the problem persists.</p>
                        {this.state?.error && (
                            <div className="bg-red-100 text-red-700 p-4 rounded mb-4">
                                <h4 className="font-semibold">Error Details:</h4>
                                <pre className="text-sm overflow-y-auto">{this.state.error?.toString()}</pre>
                            </div>
                        )}
                        {this.state?.errorInfo && (
                            <details className="text-left text-gray-600 bg-gray-50 p-3 rounded">
                                <summary className="cursor-pointer">Error Stack Trace</summary>
                                <pre className="text-xs overflow-y-auto mt-2">{this.state.errorInfo?.componentStack}</pre>
                            </details>)}
                        <p className="mt-4 text-sm text-gray-500">
                            Time of Error: {this.state.timestamp}
                        </p>                        <button
                            onClick={this.handleRetry}
                            className="mt-6 px-6 py-2 bg-blue-500 text-white font-semibold rounded hover:bg-blue-600 transition" >
                            Try Again                        </button>
                    </div>                </div>);
        }
        // Render children components if no error is found 
        return this.props.children;
    }
}
export default ErrorBoundary;