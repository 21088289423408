import {reposWebApi} from "./customerAPI";
import qs from "querystring";
import {refreshToken} from "./sessionAction";
import secureLocalStorage from "react-secure-storage";

export const GET_REFUELERS = 'GET_ALL_REFUELLERS_FOR_ORDER';
export const GET_REFUELERS_FAIL = 'GET_ALL_REFUELLERS_FOR_ORDER_FAIL';
export const GET_CONVENIENCE = 'GET_CONVENIENCE_CHARGES_FOR_ORDER';
export const GET_CONVENIENCE_FAIL = 'GET_CONVENIENCE_CHARGES_FOR_ORDER_FAIL';
export const PROCESS_ORDER_DIALOG = 'PROCESS_ORDER_DIALOG';
export const PLACE_ORDER_FAIL = 'PLACE_ORDER_FAIL';
export const ORDER_ALREADY_PRESENT = 'ORDER_ALREADY_PRESENT';
export const GET_PAST_ORDER = 'GET_PAST_ORDER';
export const ORDER_LOADING = 'LOADING';
export const GET_SINGLE_ORDER = 'GET_SINGLE_ORDER';
export const GET_ALL_ORDERS_FAIL = 'GET_ALL_ORDERS_FAIL';
export const GET_CURRENT_ORDER = 'GET_CURRENT_ORDER';
export const GET_ONGOING_ORDER = 'GET_ONGOING_ORDER';
export const GET_ALL_ORDER = 'GET_ALL_ORDER';
export const GET_OVERDUE_ORDER = 'GET_OVERDUE_ORDER';
export const GET_PLACE_ORDER = 'GET_OVERDUE_ORDER';
export const GET_PLACE_ORDER_FAIL = 'GET_OVERDUE_ORDER';
export const GET_ALL_DATUM_ORDER_REQ = 'GET_ALL_DATUM_ORDER_REQ';
export const GET_COUNT = "GET_COUNT";
export const GET_COUNT_FAIL = "GET_COUNT_FAIL";
export const GET_PAYMENT = 'GET_PAYMENT';
export const PAY_LOADING = 'PAY_LOADING';
export const EDIT_RECEIVER = "EDIT_RECEIVER";
export const EDIT_RECEIVER_FAIL = "EDIT_RECEIVER_FAIL"
export const EDIT_RECEIVER_LOADING = "EDIT_RECEIVER_LOADING";
export const CANCEL_ORDER_LOADING = "CANCEL_ORDER_LOADING";
export const CANCEL_ORDER = "CANCEL_ORDER"
export const CANCEL_ORDER_FAIL = "CANCEL_ORDER_FAIL";
export const CLEAR_ALL_DATA = 'CLEAR_ALL_DATA';
export const OFFLINE = "OFFLINE";
export const ONLINE = "ONLINE";
export const CLOSE_PAY_DIALOG = 'CLOSE_PAY_DIALOG';
export const GET_LOCATION = 'GET_LOCATION';
export const GET_LOCATION_FAIL = 'GET_LOCATION_FAIL';

//export const SESSION_EXPIRED = 'SESSION_EXPIRED';


//  **************** ALL Refuellers API ************************
export const getAllRefuelers = (formData, history, enqueueSnackbar, setQuotes) => {
    if (search) {
        search.abort();
    }

    search = new AbortController();
    const signal = search.signal;

    return (dispatch) => {
        dispatch({
            type: ORDER_LOADING,
        })
        reposWebApi.get('/refueler/get_refuelers_for_order', {
            headers: {
                Authorization: 'Bearer ' + secureLocalStorage.getItem("access_token")
            },
            signal: signal,
            params: formData
        })
            .then(response => {
                if (response?.status === 200) {
                    dispatch({
                        type: GET_REFUELERS,
                        payload: response?.data?.data
                    });
                }
            }).catch(async error => {
            if (error?.response?.status === 401) {
                await dispatch(refreshToken())
                dispatch(getAllRefuelers(formData))
            } else {
                enqueueSnackbar('Something went wrong', {
                    variant: 'error'
                });
                setQuotes(false);
            }
        });
    }
};

// ****************** Process Order API *************************
export const getOrderReview = (orderData, history, setOrderSummary) => {
    return (dispatch) => {
        dispatch({
            type: ORDER_LOADING,
        })
        reposWebApi.post('/order/process_order', qs.stringify(orderData), {
            headers: {
                Authorization: 'Bearer ' + secureLocalStorage.getItem("access_token")
            }
        }).then(response => {
            dispatch({
                type: GET_CONVENIENCE,
                payload: response?.data?.data
            });
            setOrderSummary(true);
        }).catch(async error => {
            if (error?.response?.status === 403) {
                dispatch({
                    type: ORDER_ALREADY_PRESENT,
                })
            }
            if (error?.response?.status === 401) {
                await dispatch(refreshToken())
                dispatch(getOrderReview(orderData))
            } else dispatch({
                type: GET_CONVENIENCE_FAIL,
                payload: error
            });
        })
    }
};


export const closeDialog = () => {
    return (dispatch) => {
        dispatch({
            type: PROCESS_ORDER_DIALOG,
        })
    }
};


// ***************  PAST ORDERS API *************************

export const getPastOrders = (param, fetchMoreData, setPastOrderDetail) => {
    if (search) {
        search.abort();
    }

    search = new AbortController();
    const signal = search.signal;
    return (dispatch) => {
        if (!fetchMoreData) {
            dispatch({
                type: ORDER_LOADING,
                payload: false
            })
        }
        reposWebApi.get('/order/v2/get_partner_orders_for_customer/', {
            headers: {
                Authorization: 'Bearer ' + secureLocalStorage.getItem("access_token")
            },
            signal: signal,
            params: param
        }).then(response => {
            dispatch({
                type: GET_ALL_ORDER,
                payload: response?.data?.data
            })
            if (fetchMoreData) {
                setPastOrderDetail(response?.data?.data?.results)
            }
        })
            .catch(async error => {
                if (error?.response?.status === 401) {
                    await dispatch(refreshToken())
                    dispatch(getPastOrders())
                } else dispatch({
                    type: GET_ALL_ORDERS_FAIL,
                    payload: error
                })
            })
    }
};

// ***************** Action for Single Order *******************

export const getSingleOrder = (orderData) => {


    return (dispatch) => {
        dispatch({
            type: GET_SINGLE_ORDER,
            payload: orderData
        })
    }
};

// ************ action for summary and count *********************

export const GetSummaryAndCount = (setCount) => {

    return (dispatch) => {
        dispatch({
            type: ORDER_LOADING,
        })
        reposWebApi.get('/customer/summary_data ', {
            headers: {
                Authorization: 'Bearer ' + secureLocalStorage.getItem("access_token")
            },

        }).then(response => {
            dispatch({
                type: GET_COUNT,
                payload: response?.data?.data
            })
            setCount(response?.data?.data)
        })
            .catch(async error => {
                // if (error?.response?.status === 401) {
                //     await dispatch(refreshToken())
                // } else
                dispatch({
                    type: GET_COUNT_FAIL,
                    payload: error
                })
            })
    }
};
// ********************** current order API ***********************
export const getCurrentOrder = (param, fetchMoreData, setPastOrderDetail) => {
    if (search) {
        search.abort();
    }

    search = new AbortController();
    const signal = search.signal;

    return async (dispatch) => {
        if (!fetchMoreData) {
            dispatch({
                type: ORDER_LOADING,
                payload: false
            })
        }
        await reposWebApi.get('/order/v3/get_upcoming_orders_for_customer/', {
            headers: {
                Authorization: 'Bearer ' + secureLocalStorage.getItem("access_token")
            },
            signal: signal,
            params: param
        }).then(response => {
            dispatch({
                type: GET_ALL_ORDER,
                payload: response?.data?.data
            })
            if (fetchMoreData) {
                setPastOrderDetail(response?.data?.data?.results)
            }
        })
            .catch(async error => {
                if (error?.response?.status === 401) {
                    await dispatch(refreshToken())
                    dispatch(getCurrentOrder())

                } else dispatch({
                    type: GET_ALL_ORDERS_FAIL,
                    payload: error
                })
            })
    }

};

// ********************** Ongoing order API ***********************
export const getOngoingOrder = (param, setPastOrderDetail) => {

    if (search) {
        search.abort();
    }

    search = new AbortController();
    const signal = search.signal;
    return async (dispatch) => {

        dispatch({
            type: ORDER_LOADING,
            payload: false
        })

        await reposWebApi.get('/order/v3/get_upcoming_orders_for_customer/', {
            headers: {
                Authorization: 'Bearer ' + secureLocalStorage.getItem("access_token")
            },
            signal: signal,
            params: param
        }).then(response => {
            dispatch({
                type: GET_ALL_ORDER,
                payload: response?.data?.data
            })
            setPastOrderDetail(response?.data?.data?.results)
        })
            .catch(async error => {
                if (error?.response?.status === 401) {
                    await dispatch(refreshToken())
                    dispatch(getOngoingOrder())

                } else dispatch({
                    type: GET_ALL_ORDERS_FAIL,
                    payload: error
                })
            })
    }

};

// *************** get ALL customer datum Orders API *************************

export const getAllDatumOrder = (id) => {
    if (search) {
        search.abort();
    }

    search = new AbortController();
    const signal = search.signal;
    return (dispatch) => {
        dispatch({
            type: ORDER_LOADING,
            payload: false
        })
        reposWebApi.get('/order/get_all_customer_order', {
            headers: {
                Authorization: 'Bearer ' + secureLocalStorage.getItem("access_token")
            },
            signal: signal,
            params: {
                asset_id: id
            }
        })
            .then(response => {
                dispatch({
                    type: GET_ALL_ORDER,
                    payload: response?.data?.data
                })
            })
            .catch(async error => {
                if (error?.response?.status === 401) {
                    await dispatch(refreshToken())
                    dispatch(getAllOrder())

                } else dispatch({
                    type: GET_ALL_ORDERS_FAIL,
                    payload: error
                })
            })
    }

};

// *************** get ALL customer Orders API *************************
let search = '';
export const getAllOrder = (param, fetchMoreData, setPastOrderDetail) => {
    if (search) {
        search.abort();
    }

    search = new AbortController();
    const signal = search.signal;

    return (dispatch) => {
        if (!fetchMoreData) {
            dispatch({
                type: ORDER_LOADING,
                payload: false
            })
        }
        reposWebApi.get('/order/get_all_customer_orders/', {
            headers: {
                Authorization: 'Bearer ' + secureLocalStorage.getItem("access_token")
            },
            signal: signal,
            params: param,
        }).then(response => {
            dispatch({
                type: GET_ALL_ORDER,
                payload: response?.data?.data
            })
            dispatch({
                type: ONLINE,
            })
            if (fetchMoreData) {
                setPastOrderDetail(response?.data?.data?.results)
            }
        }).catch(async error => {
            if (error.message === 'Network Error') {
                dispatch({
                    type: OFFLINE,
                })
            }
            if (error?.response?.status === 401) {
                await dispatch(refreshToken())
                dispatch(getAllOrder())

            } else dispatch({
                type: GET_ALL_ORDERS_FAIL,
                payload: error
            })
        })
    }

};

// get Datum Order Accept Req

export const getDatumOrderAcceptReq = (order_id, decision) => {

    return (dispatch) => {
        const requestBody = {
            order_id,
            decision
        }
        dispatch({
            type: ORDER_LOADING,
            payload: false
        })
        reposWebApi.post('/order/get_all_customer_orders/', qs.stringify(requestBody),
            {
                headers: {
                    Authorization: 'Bearer ' + secureLocalStorage.getItem("access_token")
                },
            })
            .then(response => {
                dispatch({
                    type: GET_ALL_DATUM_ORDER_REQ,
                    payload: response?.data
                })
            })
            .catch(async error => {
                if (error?.response?.status === 401) {
                    await dispatch(refreshToken())
                    dispatch(getAllOrder())

                } else dispatch({
                    type: GET_ALL_ORDERS_FAIL,
                    payload: error
                })
            })
    }

};

export const razorPayGateWay = (enqueueSnackbar, value, setIsPay, url) => {
    return (dispatch) => {
        dispatch({
            type: PAY_LOADING
        })
        reposWebApi.post(url ? url : '/payment/initiate_payment', qs.stringify(value), {
            headers: {
                Authorization: 'Bearer ' + secureLocalStorage.getItem("access_token")
            }
        }).then(response => {
            setIsPay(true);
            dispatch({
                type: GET_PAYMENT,
                payload: response?.data
            })
        }).catch((error) => {
            enqueueSnackbar('Something went wrong!', {
                variant: "error",
                anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "center"
                }
            })
        })
    }
}


export const getPlaceOrder = (processOrder, asset, history, pay, enqueueSnackbar, handleClose, handlePaymentGateway) => {
    console.log(processOrder);
    return (dispatch) => {
        let orderJson = {
            address: asset?.assetToBeEdited?.address_id,
            quantity: asset?.assetDetails?.quantity ? parseInt(asset?.assetDetails?.quantity) : undefined,
            ordered_amount: asset?.assetDetails?.ordered_amount ? asset?.assetDetails?.ordered_amount : undefined,
            payment_method: pay,
            // m_capital: processOrder?.convCharges?.credit_landing ? 'True' : 'False',
            m_capital:'False',
            coupon: '',
            temp_agreement_id: (processOrder?.convCharges?.temp_agreement_id)?.toString(),
            is_finserv: asset?.assetToBeEdited?.is_finserv_enabled ? '1' : "0",
            pickup: parseInt(asset?.assetDetails?.pickup),
            order_dist: asset?.rppData?.order_dist ? asset?.rppData?.order_dist : 0.0,
            customer_signature_url: 'http://www.google.com',
            customer_signature_method: 'ONSCRN',
            partner_orders: [{
                customer_asset: asset?.assetToBeEdited?.id,
                assigned_refueler: asset?.rppData?.id,
                delivery_date: asset?.setDate,
                order_placed_by: asset?.assetDetails?.quantity ? 'QTY' : 'AMT'
            }]
        };
        dispatch({
            type: ORDER_LOADING,
            payload: false
        })
        reposWebApi.post('/order/place_order/', orderJson, {
            headers: {
                Authorization: 'Bearer ' + secureLocalStorage.getItem("access_token")
            },
        }).then(response => {
            if (pay === 'OP') {
                dispatch({
                    type: PAY_LOADING
                })
                handlePaymentGateway(response?.data?.data?.invoice_number)
                // reposWebApi.post('/payment/initiate_payment', qs.stringify({
                //     invoice_number: response?.data?.data?.invoice_number,
                //     url: '/OrderSuccess'
                // }), {
                //     headers: {
                //         Authorization: 'Bearer ' + secureLocalStorage.getItem("access_token")
                //     }
                // }).then(response => {
                //     dispatch({
                //         type: GET_PAYMENT,
                //         payload: response?.data
                //     })
                //     handleClose()
                // }).catch((error) => {
                //     enqueueSnackbar('Something went wrong!', {
                //         variant: "error",
                //         anchorOrigin: {
                //             vertical: "bottom",
                //             horizontal: "center"
                //         }
                //     })
                //     handleClose()
                // })

            } else {
                dispatch({
                    type: GET_PLACE_ORDER,
                    payload: response?.data?.data
                })
                handleClose()
                history.push('/OrderSuccess')
            }
        }).catch(async error => {
            if (error?.response?.status === 401) {
                await dispatch(refreshToken())
                dispatch(getAllOrder())

            } else dispatch({
                type: GET_PLACE_ORDER_FAIL,
                payload: error
            })
        })
    }

};

// ************ Edit or change Receiver *********************

export const EditReceiver = (data, enqueueSnackbar, currentTab, page, setOpenTrackOrder, setPastOrderDetail) => {
    let formData = {
        "limit": 2,
        "offset": page
    }
    return (dispatch) => {
        dispatch({
            type: EDIT_RECEIVER_LOADING,
            payload: false
        })
        reposWebApi.post('/order/add_receiver_detail', qs.stringify(data), {
            headers: {
                Authorization: 'Bearer ' + secureLocalStorage.getItem("access_token")
            }
        }).then(async response => {
            dispatch(clearData())
            enqueueSnackbar(response?.data?.data, {
                variant: 'success', anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right'
                }
            })
            await dispatch(
                currentTab === 'current' ? await getCurrentOrder(formData, true, setPastOrderDetail) :
                    currentTab === 'all' ? await getAllOrder(formData, true, setPastOrderDetail) : currentTab === 'dashboard' ? await dispatch(getOngoingOrder(formData)) : await getPastOrders(formData, true, setPastOrderDetail)
            );
            setOpenTrackOrder(false);
        }).catch(async error => {
            if (error?.response?.status === 401) {
                await dispatch(refreshToken())

            } else dispatch({
                type: EDIT_RECEIVER_FAIL,
                payload: error
            })
        })
    }

};

//************* */ Order Cance API ****************

export const CanceledOrder = (order_id, reason, enqueueSnackbar) => {

    return (dispatch) => {
        let orderJson = {
            order_id,
            reason
        };
        dispatch({
            type: CANCEL_ORDER_LOADING,
        })
        reposWebApi.post('/order/cancel_order', qs.stringify(orderJson), {
            headers: {
                Authorization: 'Bearer ' + secureLocalStorage.getItem("access_token")
            }
        }).then(response => {
            dispatch({
                type: CANCEL_ORDER,
                payload: response?.data?.data
            })
            enqueueSnackbar('Ordered Cancelled Successfully', {
                variant: 'success', anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right'
                }
            })
        }).catch(async error => {
            if (error?.response?.status === 401) {
                await dispatch(refreshToken())

            } else if (error?.response?.status === 406) {
                dispatch({
                    type: CANCEL_ORDER_FAIL,
                    payload: error
                })
                enqueueSnackbar(error?.response?.data, {
                    variant: 'error', anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right'
                    }
                })
            } else {
                dispatch({
                    type: CANCEL_ORDER_FAIL,
                    payload: error
                })
                enqueueSnackbar("something Went Wrong!", {
                    variant: 'error', anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right'
                    }
                })
            }
        })
    }

};

export const generateProformaInvoice = (orderJson, enqueueSnackbar) => {
    return (dispatch) => {
        reposWebApi.post('/order/proforma/invoice', qs.stringify(orderJson), {
            headers: {
                Authorization: 'Bearer ' + secureLocalStorage.getItem("access_token")
            }
        }).then(response => {
            enqueueSnackbar(response?.data?.data, {
                variant: 'success', anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right'
                }
            })
        }).catch(async error => {
            if (error?.response?.status === 401) {
                enqueueSnackbar('session Expired, retrying for new session!', {
                    variant: "error",
                    anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "center"
                    }
                })
                await dispatch(refreshToken())
                await dispatch(generateProformaInvoice(orderJson, enqueueSnackbar))

            }
            enqueueSnackbar("something Went Wrong!", {
                variant: 'error', anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right'
                }
            })
        })
    }
};

export const closePayDialog = () => ({
    type: "CLOSE_PAY_DIALOG",
});


// ************ Edit or change Receiver *********************

export const clearData = (order_id, data, enqueueSnackbar) => {

    return (dispatch) => {
        dispatch({
            type: CLEAR_ALL_DATA,
        })
    }

};


export const getRFSLoaction = (param) => {
    return (dispatch) => {
        reposWebApi.get('/order/get_order_route', {
            headers: {
                Authorization: 'Bearer ' + secureLocalStorage.getItem("access_token")
            },
            params: param,
        }).then(response => {
            dispatch({
                type:  GET_LOCATION,
                payload: response?.data?.data
            })
            dispatch({
                type: ONLINE,
            })
        }).catch(async error => {
            if (error.message === 'Network Error') {
                dispatch({
                    type: OFFLINE,
                })
            }
            if (error?.response?.status === 401) {
                await dispatch(refreshToken())
                dispatch(getRFSLoaction())

            } else dispatch({
                type:  GET_LOCATION_FAIL,
                payload: error
            })
        })
    }

};
