import {reposWebApi} from "./customerAPI";
import {refreshToken} from "./sessionAction";
import qs from "querystring";
import secureLocalStorage from "react-secure-storage";

export const PAYMENT_LOADING = 'LOADING';
export const GET_TRANSCATION_LIST = 'GET_TRANSCATION_LIST';
export const GET_TRANSCATION_LIST_FAIL = 'GET_TRANSCATION_LIST_FAIL';
export const GET_SELLERS_LIST = 'GET_SELLERS_LIST';
export const GET_SELLERS_LIST_FAIL = 'GET_SELLERS_LIST_FAIL';
export const GET_GRAPH_DETAILS = 'GET_GRAPH_DETAILS';
export const GET_GRAPH_DETAILS_FAIL = 'GET_GRAPH_DETAILS_FAIL';
export const GET_PAYMENT_BEHAVIOUR = 'GET_PAYMENT_BEHAVIOUR';
export const GET_PAYMENT_BEHAVIOUR_FAIL = 'GET_PAYMENT_BEHAVIOUR_FAIL';
export const GET_ORDER_REPORT = "GET_ORDER_REPORT";
export const GET_ORDER_REPORT_FAIL = "GET_ORDER_REPORT_FAIL";
export const GET_HDFC_URL_DATA = 'GET_HDFC_URL_DATA';
export const GET_HDFC_URL_FAIL = 'GET_HDFC_URL_FAIL';
export const PAYMENT_HDFC = 'PAYMENT_HDFC';
export const CLEAR_TRANSATIONS = 'CLEAR_TRANSATIONS';
export const CLOSE_HDFC_DIALOG = 'CLOSE_HDFC_DIALOG';
export const STORE_VAN_INVOICE = 'STORE_VAN_INVOICE';

//  **************** ALL Transcation API ************************

export const getAllPaymentTranscation = (formData, fetchMoreData) => {
    return (dispatch) => {
        if (!fetchMoreData) {
            dispatch({
                type: PAYMENT_LOADING,
            })
        }
        reposWebApi.get('/customer/get_customer_transactions', {
            headers: {
                Authorization: 'Bearer ' + secureLocalStorage.getItem("access_token")
            },
            params: formData
        })
            .then(response => {
                dispatch({
                    type: GET_TRANSCATION_LIST,
                    payload: response?.data?.data
                });
            })
            .catch(async error => {
                if (error?.response?.status === 401) {
                    await dispatch(refreshToken())
                    dispatch(getAllPaymentTranscation(formData))
                } else dispatch({
                    type: GET_TRANSCATION_LIST_FAIL,
                    payload: error
                })
            });
    }
};

//  **************** ALL Partner List API ************************

export const getSellerList = (params) => {
    return (dispatch) => {
        dispatch({
            type: PAYMENT_LOADING,
        })
        reposWebApi.get('/customer/get_credit_seller_list', {
            headers: {
                Authorization: 'Bearer ' + secureLocalStorage.getItem("access_token")
            },
            params
        })
            .then(response => {
                dispatch({
                    type: GET_SELLERS_LIST,
                    payload: response?.data?.data
                });
            })
            .catch(async error => {
                if (error?.response?.status === 401) {
                    await dispatch(refreshToken())
                    dispatch(getSellerList())
                } else dispatch({
                    type: GET_SELLERS_LIST_FAIL,
                    payload: error
                })
            });
    }
};

//  **************** Graph List API ************************

export const getGraphDetails = (formData, loadChart) => {
    return async (dispatch) => {
        dispatch({
            type: PAYMENT_LOADING,
        })
        await reposWebApi.get('/customer/graph_credit_utilization', {
            headers: {
                Authorization: 'Bearer ' + secureLocalStorage.getItem("access_token")
            },
            params: formData
        }).then(response => {
            dispatch({
                type: GET_GRAPH_DETAILS,
                payload: response?.data?.data
            });
            loadChart(response?.data?.data);
        }).catch(async error => {
            if (error?.response?.status === 401) {
                await dispatch(refreshToken())
                dispatch(getGraphDetails())
            } else dispatch({
                type: GET_GRAPH_DETAILS_FAIL,
                payload: error
            })
        });
    }
};

//  **************** Payment Behaviour API ************************

export const getPaymentBehaviour = (formData) => {
    return async (dispatch) => {
        dispatch({
            type: PAYMENT_LOADING,
        })
        await reposWebApi.get('/customer/get_payment_behaviour', {
            headers: {
                Authorization: 'Bearer ' + secureLocalStorage.getItem("access_token")
            },
            params: formData
        }).then(response => {
            dispatch({
                type: GET_PAYMENT_BEHAVIOUR,
                payload: response?.data?.data
            });
        }).catch(async error => {
            if (error?.response?.status === 401) {
                await dispatch(refreshToken())
                dispatch(getPaymentBehaviour())
            } else dispatch({
                type: GET_PAYMENT_BEHAVIOUR_FAIL,
                payload: error
            })
        });
    }
};


//  **************** Sent all order report on mail API ************************

export const getSentReport = (enqueueSnackbar, setIsLoading) => {
    return async (dispatch) => {
        // dispatch({
        //     type: PAYMENT_LOADING,
        // })
        setIsLoading(true);
        await reposWebApi.get('/order/send_all_order_report', {
            headers: {
                Authorization: 'Bearer ' + secureLocalStorage.getItem("access_token")
            },
        }).then(response => {
            dispatch({
                type: GET_ORDER_REPORT,
                payload: response?.data?.data
            });
            setIsLoading(false);
            enqueueSnackbar("Report send successfully on your mail..!", {
                variant: 'success', anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right'
                }
            })
        }).catch(async error => {
            if (error?.response?.status === 401) {
                await dispatch(refreshToken())
                dispatch(getSentReport())
            } else dispatch({
                type: GET_ORDER_REPORT_FAIL,
                payload: error
            })
        });
    }
};

export const paymentViaHDFC = (enqueueSnackbar, value, RedirectPage) => {
    return async (dispatch) => {
        dispatch({
            type: PAYMENT_HDFC,
        })
        await reposWebApi.post('/payment/hdfc_initiate_payment', qs.stringify(value), {
            headers: {
                Authorization: 'Bearer ' + secureLocalStorage.getItem("access_token")
            },
        }).then(response => {
            dispatch({
                type: GET_HDFC_URL_DATA,
                payload: response?.data?.data
            });
            RedirectPage(response?.data?.data);
            enqueueSnackbar("hdfc payment get way is ready..!", {
                variant: 'success', anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right'
                }
            })
        }).catch(async error => {
            if (error?.response?.status === 401) {
                await dispatch(refreshToken())
                dispatch(paymentViaHDFC(enqueueSnackbar, value))
            } else dispatch({
                type: GET_HDFC_URL_FAIL,
                payload: error
            })
        });
    }
};

export const clearTranslation = () => {

    return async (dispatch) => {
        dispatch({
            type: CLEAR_TRANSATIONS,
        })
    }

}

export const closeHDFCDialog = () => ({
    type: "CLOSE_HDFC_DIALOG",
});

export const storeVanAndInvoice = (data) => ({
    type: 'STORE_VAN_INVOICE',
    payload: data
});