import {appVersion, client_id, client_secret, reposWebApi} from './customerAPI';
import qs from 'querystring';
import {
    ASSET_LOADING,
    ASSET_TYPE_COUNT,
    ASSET_TYPE_COUNT_LOADING,
    AssetTypeCount,
    GET_ASSETS,
    GET_ASSETS_FAIL,
    SESSION_EXPIRED
} from "./getAssetActions";
import fernet from "fernet";
import secureLocalStorage from "react-secure-storage";
import encryptedData from "../components/encryption/Encrytion";
import decryption from "../components/encryption/Decryption";

export const LOADING = 'LOADING';
export const LOADING_FALSE = 'LOADING_FALSE';
export const SESSION_LOGIN = 'SESSION_LOGIN';
export const DETAILS = 'DETAILS';
export const SESSION_LOGIN_ERROR = 'SESSION_LOGIN_ERROR';
export const SESSION_LOGOUT = 'SESSION_LOGOUT';
export const UPDATE_PROFILE_SUCCESS = 'UPDATE_PROFILE_SUCCESS';
export const UPDATE_PROFILE_ERROR = 'UPDATE_PROFILE_ERROR';
export const UPDATE_BILLING_SUCCESS = 'UPDATE_PROFILE_SUCCESS';
export const UPDATE_BILLING_ERROR = 'UPDATE_PROFILE_ERROR';
export const UPDATE_BANK_SUCCESS = 'UPDATE_PROFILE_SUCCESS';
export const UPDATE_BANK_ERROR = 'UPDATE_PROFILE_ERROR';
export const FORGOT_PASSWORD_SUCCESS = 'FORGOT_PASSWORD_SUCCESS';
export const FORGOT_PASSWORD_FAIL = 'FORGOT_PASSWORD_FAIL';
export const GET_OTP_FOR_LOGIN = 'GET_OTP_FOR_LOGIN';
export const GET_OTP_FOR_LOGIN_ERROR = 'GET_OTP_FOR_LOGIN_ERROR';
export const GET_OTP_FOR_FP = "GET_OTP_FOR_FP";
export const GET_OTP_FOR_FP_ERROR = "GET_OTP_FOR_FP_ERROR";
export const FORGOT_LOADING = 'FORGOT_LOADING';
export const VERIFY_USER = 'VERIFY_USER';
export const TAB_OTP = 'TAB_OTP';
export const TAB_FORGOT_PASSWORD = 'TAB_FORGOT_PASSWORD';
export const VERIFY_FP_OTP_FAIL = 'VERIFY_FP_OTP_FAIL';
export const VERIFY_FP_OTP = 'VERIFY_FP_OTP';
export const VERIFY_SIGNUP_OTP_FAIL = 'VERIFY_SIGNUP_OTP_FAIL';
export const VERIFY_SIGNUP_OTP = 'VERIFY_SIGNUP_OTP';
export const LOGIN_USING_OTP = "LOGIN_USING_OTP"
export const CHANGE_PASSWORD_LOADING = "CHANGE_PASSWORD_LOADING";
export const CHANGE_PASSWORD_SUCCESS = "CHANGE_PASSWORD_SUCCESS";
export const CHANGE_PASSWORD_FAIL = "CHANGE_PASSWORD_FAIL";
export const TAB_SIGN_UP = "TAB_SIGN_UP";
export const TAB_SIGN_UP_DETAILS = "TAB_SIGN_UP_DETAILS";
export const SIGN_UP = "SIGN_UP";
export const GET_OTP_FOR_SIGNUP = "GET_OTP_FOR_SIGNUP";
export const GET_OTP_FOR_SIGNUP_ERROR = "GET_OTP_FOR_SIGNUP_ERROR";
export const TAB_SIGNUP_PASSWORD = "TAB_SIGNUP_PASSWORD";
export const TAB_SIGN_UP_EMAIL_OTP = "TAB_SIGN_UP_EMAIL_OTP";
export const TAB_LOGIN_PASS = "TAB_LOGIN_PASS";
export const COMPLAINT = "COMPLAINT";
export const SUB_COMPLAINT = "SUB_COMPLAINT";
export const COMPLAINT_LIST = "COMPLAINT_LIST";
export const DATUM_ORDER_SUCCESS = "DATUM_ORDER_SUCCESS";
export const DATUM_ORDER_ERROR = "DATUM_ORDER_ERROR";
export const TAB_SIGN_UP_PHONE = "TAB_SIGN_UP_PHONE";
export const REFERRAL = "REFERRAL";
export const ADD_REFERRAL = "ADD_REFERRAL";
export const RAISE_COMPLAINT_FAILED = "RAISE_COMPLAINT_FAILED";
export const CLEAR_COMPLAIN_DATA = "CLEAR_COMPLAIN_DATA";
export const GET_OTP_FOR_LOGIN_MSG = 'GET_OTP_FOR_LOGIN_MSG';
export const GET_OTP_FOR_LOGIN_MSG_CLEAR = 'GET_OTP_FOR_LOGIN_MSG_CLEAR';
export const REFRESH = 'REFRESH';
export const FLEET_ACCOUNT = 'FLEET_ACCOUNT';
export const VAN_HISTORY = 'VAN_HISTORY';
export const ADD_VAN_ACCOUNT = 'ADD_VAN_ACCOUNT';
export const GET_OTP_FOR_FLEET = 'GET_OTP_FOR_FLEET';
export const GET_OTP_FOR_FLEET_MSG = 'GET_OTP_FOR_FLEET_MSG';
export const GET_OTP_FOR_FLEET_ERROR = 'GET_OTP_FOR_FLEET_ERROR';
export const VERIFY_FLEET_OTP = 'VERIFY_FLEET_OTP';
export const VERIFY_FLEET_OTP_FAIL = 'VERIFY_FLEET_OTP_FAIL';
export const PLACE_ORDER = 'PLACE_ORDER';
export const ADD_VAN_ACCOUNT_ERROR = 'ADD_VAN_ACCOUNT_ERROR';
export const CLEAR_VAN_HISTORY = 'CLEAR_VAN_HISTORY';

const config = {
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    }
};

let screenKey;
let abortsignal = '';


function containsOnlyNumbers(str) {
    return /^\d+$/.test(str);
}


// const encryptedData = (data) => {
//
//     let secret = new fernet.Secret('BbSePhsFh4eJ5U5hUkfTm_Y1R1wS4PhWnLltHUPiMW8');
//     let token = new fernet.Token({
//         secret: secret,
//         // time: Date.parse(1),
//         // iv: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15]
//     })
//
//     token.encode("string");
//
//     let toke = new fernet.Token({
//         secret: secret,
//         token: token.encode(data),
//         ttl: 0
//     });
//
//     console.log(JSON.parse(toke.decode()));
//
//     return token.encode(data)
// }


export const login = (enqueueSnackbar, userData, router, fromSignup, setAttains, attains) => {
    //secureLocalStorage.clear()
    let requestBody = {};
    if (userData.mobile) {
        if (fromSignup === "login") {
            requestBody = {
                mobile: "",
                email: userData.mobile,
                c_code: "",
                password: userData.password,
                is_email: '1',
                is_username: '0',
                client_secret: client_secret,
                client_id: client_id,
                grant_type: 'password',
            };
        } else if (fromSignup === "otp") {
            requestBody = {
                grant_type: 'password',
                password: 123,
                mobile: userData.mobile,
                is_otp: 1,
                client_secret: client_secret,
                client_id: client_id,
                otp: userData.otp,
            };
        } else if (fromSignup === "otpEmail") {
            requestBody = {
                grant_type: 'password',
                password: 123,
                is_email: 1,
                email: userData.mobile,
                is_username: '0',
                is_otp: 1,
                client_secret: client_secret,
                client_id: client_id,
                otp: userData.otp,
            };
        } else {
            requestBody = {
                mobile: userData.mobile,
                email: "",
                c_code: userData.c_code,
                password: userData.password,
                is_email: '0',
                is_username: '0',
                client_secret: client_secret,
                client_id: client_id,
                grant_type: 'password',
            };
        }
    } else {
        function validateEmail(e_mail) {
            // eslint-disable-next-line
            var re = /^(?:[a-z0-9!#$%&amp;'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&amp;'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/;

            return re.test(e_mail);
        }


        function telephoneCheck(str) {
            // eslint-disable-next-line
            var a = /^(1\s|1|)?((\(\d{3}\))|\d{3})(\-|\s)?(\d{3})(\-|\s)?(\d{4})$/;
            return a.test(str)
        }

        if (userData.inputName) {
            if (validateEmail(userData.inputName)) {
                requestBody = {
                    email: userData.inputName,
                    password: userData.password,
                    is_email: '1',
                    is_username: '0',
                    client_secret: client_secret,
                    client_id: client_id,
                    grant_type: 'password',
                };
            } else if (userData.inputName.length === 10) {
                if (telephoneCheck(userData.inputName)) {
                    requestBody = {
                        email: userData.email,
                        password: userData.password,
                        mobile: userData.inputName,
                        is_email: '0',
                        is_username: '0',
                        client_secret: client_secret,
                        client_id: client_id,
                        grant_type: 'password',
                    };
                } else {
                    enqueueSnackbar("In-valid Mobile Number, please correct and try again!");
                }
            } else {
                enqueueSnackbar("In-valid E-mail, please correct and try again!");
            }
        }
    }

    return async (dispatch) => {
        dispatch({
            type: LOADING,
        });
        await reposWebApi.post('/customer/login', qs.stringify(requestBody), config)
            .then(async response => {
                if (response.data.expires_in === 36000) {
                    secureLocalStorage.setItem('user', JSON.stringify(response.data));
                    secureLocalStorage.setItem('userId', JSON.stringify(response?.data?.user_details?.id));
                    secureLocalStorage.setItem('access_token', response.data.access_token);
                    secureLocalStorage.setItem('refresh_token', response.data.refresh_token);
                    localStorage.setItem('is_first', true);
                    await dispatch(AssetType(router))
                    dispatch({
                        type: SESSION_LOGIN,
                        payload: response.data
                    });
                    window.location.reload();
                } else {
                    await dispatch(AssetType(router))
                }
            }).catch(error => {
                if (error?.response?.status === 401) {
                    dispatch({
                        type: SESSION_EXPIRED,
                    })
                } else if (error?.response?.status === 406) {
                    dispatch({
                        type: LOADING_FALSE,
                    });
                    setAttains(attains - 1);
                    if (requestBody.is_otp === 1) {
                        dispatch({
                            type: LOADING_FALSE,
                        });
                        if (error?.response?.data?.exception) {
                            enqueueSnackbar(error?.response?.data?.exception, {
                                variant: 'error', anchorOrigin: {
                                    vertical: 'top',
                                    horizontal: 'right'
                                }
                            });
                        } else {
                            enqueueSnackbar('Invalid OTP', {
                                variant: 'error', anchorOrigin: {
                                    vertical: 'top',
                                    horizontal: 'right'
                                }
                            })
                        }
                    } else if (error?.response?.data?.exception) {
                        enqueueSnackbar(error?.response?.data?.exception, {
                            variant: 'error', anchorOrigin: {
                                vertical: 'top',
                                horizontal: 'right'
                            }
                        })
                    } else {
                        enqueueSnackbar(error?.response?.data?.Authorization, {
                            variant: 'error', anchorOrigin: {
                                vertical: 'top',
                                horizontal: 'right'
                            }
                        })
                        dispatch({
                            type: SESSION_LOGIN_ERROR,
                            payload: error
                        });
                    }
                } else if (error?.response?.status === 429) {
                    dispatch({
                        type: SESSION_LOGIN_ERROR,
                        payload: error
                    });
                    enqueueSnackbar(error?.response?.data?.exception, {
                        variant: 'error', anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        }
                    })
                }
            })
    }
};

export const changesPage = () => {
    return (dispatch) => {
        dispatch({
            type: VERIFY_USER,
            payload: undefined
        });
    }
}

export const verifyUser = (userData, router, enqueueSnackbar, signup_value) => {
    console.log(signup_value);
    //secureLocalStorage.clear()
    let requestBody = {};
    let requestBody1 = {};
    let queryParams = {};
    queryParams.mobile_email = userData.inputName;
    queryParams.c_code = userData.c_code


    return async (dispatch) => {
        dispatch({
            type: LOADING,
        });
        await reposWebApi.get("/check_existing_user", {
            headers: {
                Authorization: "Bearer " + secureLocalStorage.getItem("access_token"),
            },
            params: queryParams,
        })
            .then(async response => {
                dispatch({
                    type: VERIFY_USER,
                    payload: response?.data?.data
                });
                //changeScreen("loginPass", userData.inputName)
                if (signup_value === "signup") {
                    if (response?.data?.data?.user_exists === "YES") {
                        enqueueSnackbar("User already exists", {
                            variant: 'error', anchorOrigin: {
                                vertical: 'top',
                                horizontal: 'right'
                            }
                        })
                        // delete session?.verify_user?.user_exists
                    } else {
                        if (containsOnlyNumbers(userData?.inputName)) {
                            requestBody1 = {
                                cust_mobile: userData?.inputName,
                                c_code: userData?.c_code,
                                // cust_email: 'None'
                            }
                        } else {
                            requestBody1 = {
                                cust_email: userData?.inputName,
                            }
                        }

                        dispatch(signupOTP(userData?.inputName, encryptedData(JSON.stringify(requestBody1)), enqueueSnackbar));
                    }
                }

            }).catch(error => {
                if (error?.response?.status === 401) {
                    dispatch({
                        type: SESSION_EXPIRED,
                    })
                } else if (error?.response?.status === 406) {
                    dispatch({
                        type: LOADING_FALSE,
                    });
                    if (requestBody.is_otp === 1) {
                        dispatch({
                            type: LOADING_FALSE,
                        });
                        if (error?.response?.data?.exception) {
                            enqueueSnackbar(error?.response?.data?.exception, {
                                variant: 'error', anchorOrigin: {
                                    vertical: 'top',
                                    horizontal: 'right'
                                }
                            });
                        } else {
                            enqueueSnackbar('Invalid OTP', {
                                variant: 'error', anchorOrigin: {
                                    vertical: 'top',
                                    horizontal: 'right'
                                }
                            })
                        }
                    } else if (error?.response?.data?.exception) {
                        enqueueSnackbar(error?.response?.data?.exception, {
                            variant: 'error', anchorOrigin: {
                                vertical: 'top',
                                horizontal: 'right'
                            }
                        })
                    } else {
                        enqueueSnackbar(error?.response?.data?.Authorization, {
                            variant: 'error', anchorOrigin: {
                                vertical: 'top',
                                horizontal: 'right'
                            }
                        })
                        dispatch({
                            type: SESSION_LOGIN_ERROR,
                            payload: error
                        });
                    }
                } else {
                    dispatch({
                        type: SESSION_LOGIN_ERROR,
                        payload: error
                    });
                }
            })
    }
};

export const formReset = () => {
    return async (dispatch) => {
        dispatch({
            type: SESSION_LOGIN_ERROR,
        });
    }
}

export const refreshToken = () => {
    if (secureLocalStorage.getItem("access_token")) {
        return async (dispatch) => {
            dispatch({
                type: LOADING,
            });
            let requestBody = {
                refresh_token: secureLocalStorage.getItem("refresh_token"),
                client_secret: client_secret,
                client_id: client_id,
            };
            await reposWebApi.post('/get/refresh/token', qs.stringify(requestBody), config)
                .then(response => {
                    if (response.data.error) {
                        dispatch(logout())
                    } else {
                        secureLocalStorage.setItem('access_token', response.data.access_token);
                        secureLocalStorage.setItem('refresh_token', response.data.refresh_token);
                        dispatch({
                            type: SESSION_LOGIN,
                            payload: response.data
                        });
                        dispatch(details())
                    }
                }).catch(err => {
                    if (err?.response?.status === 500) {
                        dispatch({
                            type: LOADING_FALSE,
                        });
                        window.location.reload();
                        dispatch(logout());
                    }
                    window.location.reload();
                    dispatch(logout());
                })
        }
    }

}

export const getAsset = (searchKey, asset_state, asset_type, formData, fetchMoreData) => {
    if (abortsignal) {
        abortsignal.abort();
    }

    abortsignal = new AbortController();
    const signal = abortsignal.signal;

    return async (dispatch) => {
        dispatch(AssetTypeCount);
        const requestBody = {
            asset_type: asset_type,
            asset_state: asset_state,
            search: searchKey,
            limit: formData?.limit ? formData.limit : 5,
            offset: formData?.offset ? formData?.offset : 0
        };
        // if (!fetchMoreData) {
        //     dispatch({
        //         type: ASSET_LOADING
        //     })
        // }
        if (!fetchMoreData) {
            dispatch({
                type: LOADING,
            })
        }
        await reposWebApi.get('/customer/v2/get_customer_assets', {
            headers: {
                Authorization: 'Bearer ' + secureLocalStorage.getItem("access_token")
            },
            signal: signal,
            params: requestBody
        }).then(response => {
            var i;
            for (i = 0; i < response?.data?.data?.results.length; ++i) {
                if (response?.data?.data.results[i]?.asset_type === 1) {
                    secureLocalStorage.setItem('has_datum', true);
                    break;
                } else {
                    secureLocalStorage.setItem('has_datum', false);
                }
            }
            // if (response?.data.data.length === 0) {
            secureLocalStorage.setItem('assetCount', JSON.stringify(response?.data));
            // }
            dispatch({
                type: GET_ASSETS,
                payload: response?.data?.data
            })
        }).catch(async error => {
            if (error?.response?.status === 401) {
                await dispatch(refreshToken())
                dispatch(getAsset())
            }
            if (error?.response?.state === 502 || error?.response?.state === 504) {
                dispatch(logout());
            } else dispatch({
                type: GET_ASSETS_FAIL,
            });
        });
    }
};


export const AssetType = (router) => {
    return (dispatch) => {
        dispatch({
            type: ASSET_TYPE_COUNT_LOADING,
        });
        reposWebApi
            .get("customer/asset_type_count", {
                headers: {
                    Authorization: "Bearer " + secureLocalStorage.getItem("access_token"),
                },
            })
            .then((response) => {
                secureLocalStorage.setItem('asset_type', JSON.stringify(response))
                dispatch({
                    type: ASSET_TYPE_COUNT,
                    payload: response?.data?.data,
                });
            })
            .catch(async (error) => {
                if (error?.response?.status === 401) {
                    await dispatch(refreshToken());
                    dispatch(AssetTypeCount());
                }
                if (error?.response?.state === 502 || error?.response?.state === 504) {
                    dispatch(logout());
                } else
                    dispatch({
                        type: GET_ASSETS_FAIL,
                    });
            });
    };
    // }
};

export const details = () => {
    if (abortsignal) {
        abortsignal.abort();
    }

    abortsignal = new AbortController();
    const signal = abortsignal.signal;

    return async (dispatch) => {
        await reposWebApi.get('/customer/detail/', {
            headers: {
                Authorization: 'Bearer ' + secureLocalStorage.getItem("access_token")
            },
            signal: signal,
        }).then(res => {
            secureLocalStorage.setItem('userDetails', JSON.stringify(res?.data));
            secureLocalStorage.setItem('notification', parseInt(0));
            dispatch({
                type: DETAILS,
                payload: res?.data?.data
            })
        }).catch(error => {
            if (error?.response?.status === 401) {
                dispatch(refreshToken())
            }
            if (error?.response?.state === 502 || error?.response?.state === 504) {
                dispatch(logout());
            }
        });
    }
};

export const getOtp = (mobile, c_code, enqueueSnackbar) => {


    const requestBody = {
        mobile: mobile,
        c_code: c_code,

    };

    return (dispatch) => {
        reposWebApi.post('/login/otp', qs.stringify(requestBody), config)
            .then(response => {
                if (response?.status === 200) {
                    // setLoading(false);
                    dispatch({
                        type: GET_OTP_FOR_LOGIN,
                        payload: true
                    });

                    enqueueSnackbar(response?.data?.data, {
                        variant: 'success', anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        }
                    })
                    dispatch(login(enqueueSnackbar))
                }
            })
            .catch(error => {
                if (error?.response?.status === 401) {
                    dispatch({
                        type: SESSION_EXPIRED,
                    })
                }
                if (error?.response?.status === 429) {

                    const matches = error?.response?.data?.data.match(/\d+/);
                    const number = matches[0];

                    dispatch({
                        type: GET_OTP_FOR_LOGIN_MSG,
                        payload: number
                    });

                    enqueueSnackbar(error?.response?.data?.data, {
                        variant: 'warning', anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        }
                    })
                }
                if (error?.response?.status === 406) {
                    enqueueSnackbar(error.response.data.data, {
                        variant: 'error', anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        }
                    });
                    dispatch({
                        type: GET_OTP_FOR_LOGIN_ERROR,
                        payload: true
                    });
                }
            })
    }
};


export const getFpOtp = (mobile, c_code, enqueueSnackbar) => {
    let requestBody;
    if (containsOnlyNumbers(parseInt(mobile))) {
        requestBody = {
            mobile: mobile,
            c_code: c_code
        };
    } else {
        requestBody = {
            email: mobile
        };
    }

    return (dispatch) => {
        reposWebApi.post('/customer/send/forgot/otp', qs.stringify(requestBody), config)
            .then(response => {
                if (response?.status === 200) {
                    // setLoading(false);
                    enqueueSnackbar(response?.data?.data, {
                        variant: 'success', anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        }
                    })
                    dispatch({
                        type: GET_OTP_FOR_FP,
                        payload: true
                    });


                }
            })
            .catch(error => {
                if (error?.response?.status === 401) {
                    dispatch({
                        type: SESSION_EXPIRED,
                    })
                }
                if (error?.response?.status === 406) {
                    enqueueSnackbar(error.response.data.data, {
                        variant: 'error', anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        }
                    });
                    dispatch({
                        type: GET_OTP_FOR_FP_ERROR,
                        payload: true
                    });
                }
            })
    }
};

export const verifyFpOtp = (otp, mobile, enqueueSnackbar, setIsValidOTP) => {

    const requestBody = {
        otp: otp,
        mobile: mobile,
    };

    let secret = new fernet.Secret('K_5H0OzfMLk_FIcGQkg_H5W3WHNBRQWMoAmKCuEhNeY=');
    let token = new fernet.Token({
        secret: secret
    });
    token.encode("string");


    return (dispatch) => {
        dispatch({
            type: FORGOT_LOADING,
        });
        reposWebApi.post('/customer/verify/forgot/otp_v2', qs.stringify(requestBody))
            .then(response => {
                dispatch({
                    type: VERIFY_FP_OTP,
                    payload: response?.data?.data
                });

                let toke = new fernet.Token({
                    secret: secret,
                    token: response?.data?.data,
                    ttl: 0
                });

                setIsValidOTP(JSON.parse(toke.decode()))

                enqueueSnackbar(JSON.parse(toke.decode()), {
                    variant: JSON.parse(toke.decode()) === "Wrong OTP" ? 'error' : 'success', anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right'
                    }
                });

            })
            .catch(error => {
                if (error?.response?.status === 401) {
                    dispatch({
                        type: SESSION_EXPIRED,
                    })
                }
                if (error?.response?.status === 406) {
                    enqueueSnackbar("invalid OTP", {
                        variant: 'error', anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        }
                    });
                    dispatch({
                        type: VERIFY_FP_OTP_FAIL,
                    })
                }
                if (error?.response?.status === 422) {

                    let toke = new fernet.Token({
                        secret: secret,
                        token: error?.response?.data?.data,
                        ttl: 0
                    });

                    enqueueSnackbar(toke.decode(), {
                        variant: 'error', anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        }
                    });
                    dispatch({
                        type: VERIFY_FP_OTP_FAIL,
                    })
                }
                dispatch({
                    type: VERIFY_FP_OTP_FAIL,
                    payload: error
                });
            })
    }
}

export const forgotPassword = (password, cnfpassword, mobile, enqueueSnackbar) => {

    const requestBody = {
        password: password,
        confirm_password: cnfpassword,
        mobile_email: mobile,
    };

    return (dispatch) => {
        dispatch({
            type: FORGOT_LOADING,
        });
        reposWebApi.post('/customer/v2forgot/password', qs.stringify(requestBody))
            .then(response => {
                if (response.status === 200) {
                    dispatch({
                        type: FORGOT_PASSWORD_SUCCESS,
                        payload: response?.data?.data
                    });
                    enqueueSnackbar("Password Changed", {
                        variant: 'success', anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        }
                    });
                    window.location.reload();
                }
            })
            .catch(error => {
                if (error?.response?.status === 401) {
                    dispatch({
                        type: SESSION_EXPIRED,
                    })
                }
                if (error?.response?.status === 406) {
                    enqueueSnackbar("No user found", {
                        variant: 'error', anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        }
                    });
                    window.location.reload();
                    dispatch({
                        type: FORGOT_PASSWORD_FAIL,
                    })
                }
                dispatch({
                    type: FORGOT_PASSWORD_FAIL,
                    payload: error
                });
            })
    }
};

export const changeScreen = (key, inputName) => {
    screenKey = key;
    if (key === 'OTP') {
        return (dispatch) => {
            dispatch({
                type: TAB_OTP,
                payload: inputName
            });
        }
    } else if (key === 'loginPass') {
        return (dispatch) => {
            dispatch({
                type: TAB_LOGIN_PASS,
                payload: inputName
            });
        }
    } else if (key === 'FP') {
        return (dispatch) => {
            dispatch({
                type: TAB_FORGOT_PASSWORD,
                payload: inputName
            })
        }
    } else if (key === 'signupNumber') {
        return (dispatch) => {
            dispatch({
                type: TAB_SIGN_UP_PHONE,
            })
        }
    } else if (key === 'SignupOtp') {
        return (dispatch) => {
            dispatch({
                type: TAB_SIGN_UP,
                payload: inputName
            })
        }
    } else if (key === 'signupDetails') {
        return (dispatch) => {
            dispatch({
                type: TAB_SIGN_UP_DETAILS,
                payload: inputName
            })
        }

    } else if (key === 'signupemailotp') {
        return (dispatch) => {
            dispatch({
                type: TAB_SIGN_UP_EMAIL_OTP,
                payload: inputName
            })
        }
    } else if (key === 'passwordSignup') {
        return (dispatch) => {
            dispatch({
                type: TAB_SIGNUP_PASSWORD,
                payload: inputName
            })
        }
    } else {
        return (dispatch) => {
            dispatch({
                type: LOGIN_USING_OTP,
                payload: inputName
            })
        }
    }
};

export const changePassword = (formData, enqueueSnackbar) => {

    return (dispatch) => {
        dispatch({
            type: CHANGE_PASSWORD_LOADING,
        });
        reposWebApi.post('/customer/change_password', qs.stringify(formData),
            {
                headers: {
                    Authorization: 'Bearer ' + secureLocalStorage.getItem("access_token")
                }
            }).then((response) => {
            enqueueSnackbar("Password Changed Successfully", {
                variant: 'success', anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right'
                }
            });
            dispatch({
                type: CHANGE_PASSWORD_SUCCESS,
                payload: response?.data?.data,
            });
        })
            .catch((error) => {
                if (error?.response?.status === 401) {
                    dispatch({
                        type: SESSION_EXPIRED,
                    });
                }
                if (error?.response?.status === 404) {
                    enqueueSnackbar('User not found', {
                        variant: 'error', anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        }
                    });
                    dispatch({
                        type: CHANGE_PASSWORD_FAIL,
                        payload: 'New Password and Re-enter Password are not same ',
                    });
                } else if (error?.response?.status === 406) {
                    enqueueSnackbar('Old password is incorrect', {
                        variant: 'error', anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        }
                    });
                    dispatch({
                        type: CHANGE_PASSWORD_FAIL,
                        payload: 'Old password is incorrect',
                    });
                } else if (error?.response?.status === 403) {
                    enqueueSnackbar('your new password and renter password is mismatched', {
                        variant: 'error', anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        }
                    });
                    dispatch({
                        type: CHANGE_PASSWORD_FAIL,
                        payload: 'Old password is incorrect',
                    })
                } else {
                    dispatch({
                        type: CHANGE_PASSWORD_FAIL,
                        payload: error,
                    });
                }
            });
    };
};

export const updateProfile = (formData, enqueueSnackbar) => {
    return (dispatch) => {
        dispatch({
            type: FORGOT_LOADING,
        });
        reposWebApi.post('/customer/v2/update_profile', qs.stringify(formData), {
            headers: {
                Authorization: 'Bearer ' + secureLocalStorage.getItem("access_token")
            }
        })
            .then(response => {
                enqueueSnackbar(response?.data?.data?.msg, {
                    variant: 'success', anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right'
                    }
                });
                dispatch({
                    type: UPDATE_PROFILE_SUCCESS,
                    payload: response?.data?.data
                });
                dispatch(details());
            })
            .catch(error => {
                if (error?.response?.status === 401) {
                    dispatch({
                        type: SESSION_EXPIRED,
                    })
                }
                if (error?.response?.status === 406) {
                    alert("No user found");
                    window.location.reload();
                    dispatch({
                        type: UPDATE_PROFILE_ERROR,
                    })
                }
                dispatch({
                    type: UPDATE_PROFILE_ERROR,
                    payload: error
                });
            })
    }
};

export const updateBillingDetail = (formData, enqueueSnackbar) => {
    return (dispatch) => {
        dispatch({
            type: FORGOT_LOADING,
        });
        reposWebApi.post('/customer/update_billing_detail', qs.stringify(formData), {
            headers: {
                Authorization: 'Bearer ' + secureLocalStorage.getItem("access_token")
            }
        })
            .then(response => {
                enqueueSnackbar(response?.data?.data?.msg, {
                    variant: 'success', anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right'
                    }
                });
                dispatch({
                    type: UPDATE_BILLING_SUCCESS,
                    payload: response?.data?.data
                });
                dispatch(details());
            })
            .catch(error => {
                if (error?.response?.status === 401) {
                    dispatch({
                        type: SESSION_EXPIRED,
                    })
                }
                if (error?.response?.status === 406) {
                    alert("No user found");
                    window.location.reload();
                    dispatch({
                        type: UPDATE_BILLING_ERROR,
                    })
                }
                dispatch({
                    type: UPDATE_BILLING_ERROR,
                    payload: error
                });
            })
    }
};

export const updateBankDetail = (formData, enqueueSnackbar) => {
    return (dispatch) => {
        dispatch({
            type: FORGOT_LOADING,
        });
        reposWebApi.post('/customer/update_bank_detail', qs.stringify(formData), {
            headers: {
                Authorization: 'Bearer ' + secureLocalStorage.getItem("access_token")
            },
        })
            .then(response => {
                enqueueSnackbar(response?.data?.data?.msg, {
                    variant: 'success', anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right'
                    }
                });
                dispatch({
                    type: UPDATE_BANK_SUCCESS,
                    payload: response?.data?.data
                });
                dispatch(details());
            })
            .catch(error => {
                if (error?.response?.status === 401) {
                    dispatch({
                        type: SESSION_EXPIRED,
                    })
                }
                if (error?.response?.status === 406) {
                    dispatch({
                        type: UPDATE_BANK_ERROR,
                    })
                }
                enqueueSnackbar(error?.data?.data?.msg, {
                    variant: 'error', anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right'
                    }
                });
                dispatch({
                    type: UPDATE_BANK_ERROR,
                    payload: error
                });
            })
    }
};

export const logout = () => {
        localStorage.clear();
        localStorage.setItem("is_first", true)
        return (dispatch) => {
            dispatch({
                type: SESSION_LOGOUT
            });
            // caches.keys().then((names) => {
            //     names.forEach((name) => {
            //         caches.delete(name);
            //     });
            // });
        }


};

export const signup = (mobile, password, full_name, email, company_name, enqueueSnackbar) => {
    const requestBody = {
        mobile: mobile,
        password: password,
        full_name: full_name,
        email: email,
        company_name: company_name
    };

    return (dispatch) => {
        reposWebApi.post('/customer/signup', qs.stringify(requestBody), config)
            .then(async response => {

                if (response.status === 200) {
                    dispatch({
                        type: SIGN_UP,
                        payload: response
                    });
                    enqueueSnackbar("Signup Successful", {
                        variant: 'success', anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        }
                    })
                    window.location.reload();
                } else {
                    dispatch({
                        type: 'SIGNUP_ERROR',
                        payload: true
                    });
                }
            })
            .catch(error => {
                if (error?.response?.status === 400) {
                    dispatch({
                        type: 'SIGNUP_ERROR',
                        payload: true
                    });
                    enqueueSnackbar(error?.response?.data?.data, {
                        variant: 'error', anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        }
                    })
                }
            })
    }

};


export const signupOTP = (mobile, encoder, enqueueSnackbar) => {

    let secret = new fernet.Secret('BbSePhsFh4eJ5U5hUkfTm_Y1R1wS4PhWnLltHUPiMW8=');
    let toke = new fernet.Token({
        secret: secret,
        token: encoder,
        ttl: 0
    });

    return (dispatch) => {


        dispatch({
            type: GET_OTP_FOR_SIGNUP,
            payload: false
        });

        reposWebApi.post('/signup/otp', JSON.parse(toke.decode()), config)
            .then(response => {
                if (response?.status === 200) {
                    enqueueSnackbar(JSON.parse(decryption(response)).data, {
                        variant: 'success', anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        }
                    })
                    console.log("if case:",decryption(response).data === "OTP service failed");
                    console.log(decryption(response).data);

                    if (decryption(response).data === "OTP service failed" || decryption(response).data === "This email already registered") {
                        dispatch({
                            type: GET_OTP_FOR_SIGNUP,
                            payload: false
                        });
                    }else {
                        dispatch({
                            type: GET_OTP_FOR_SIGNUP,
                            payload: true
                        });

                        if (screenKey !== "signupDetails") {
                            dispatch(changeScreen('SignupOtp', mobile))
                        }
                    }

                }
            })
            .catch(error => {
                if (error?.response?.status === 401) {
                    dispatch({
                        type: SESSION_EXPIRED,
                    })
                    enqueueSnackbar(error.response.data.data, {
                        variant: 'error', anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        }
                    });
                }
                if (error?.response?.status === 400) {
                    dispatch({
                        type: GET_OTP_FOR_SIGNUP_ERROR,
                        payload: true
                    });
                    enqueueSnackbar(error.response.data.data, {
                        variant: 'error', anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        }
                    });
                }
                if (error?.response?.status === 406) {
                    dispatch({
                        type: GET_OTP_FOR_SIGNUP_ERROR,
                        payload: true
                    });
                    enqueueSnackbar(error.response.data.data, {
                        variant: 'error', anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        }
                    });
                }
            })
    }
}

export const verifySignupOtp = (otp, mobile, enqueueSnackbar) => {
    let requestBody = {}
    if (screenKey === "signupDetails") {
        requestBody = {
            otp: otp,
            email: mobile,
        };
    } else {
        requestBody = {
            otp: otp,
            mobile: mobile,
        };
    }

    let secret = new fernet.Secret('K_5H0OzfMLk_FIcGQkg_H5W3WHNBRQWMoAmKCuEhNeY=');
    let token = new fernet.Token({
        secret: secret
    });
    token.encode("string");

    return (dispatch) => {
        dispatch({
            type: FORGOT_LOADING,
        });
        reposWebApi.post('/customer/verify/signup/otp_v3', qs.stringify(requestBody))
            .then(response => {

                let toke = new fernet.Token({
                    secret: secret,
                    token: response?.data?.data,
                    ttl: 0
                });

                dispatch({
                    type: VERIFY_SIGNUP_OTP,
                    payload: toke.decode()
                });


                enqueueSnackbar(JSON.parse(toke.decode()), {
                    variant: JSON.parse(toke.decode()) === "Wrong OTP" ? 'error' : 'success', anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right'
                    }
                });

                if (JSON.parse(toke.decode()) === "OTP verified successfully") {
                    if (screenKey === "signupDetails") {
                        dispatch(changeScreen("passwordSignup", mobile))
                    } else {
                        dispatch(changeScreen("signupDetails", mobile))
                    }
                }
            })
            .catch(error => {
                if (error?.response?.status === 401) {
                    dispatch({
                        type: SESSION_EXPIRED,
                    })
                }
                if (error?.response?.status === 422) {
                    enqueueSnackbar("invalid OTP", {
                        variant: 'error', anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        }
                    });
                    dispatch({
                        type: VERIFY_SIGNUP_OTP_FAIL,
                    })
                }
                dispatch({
                    type: VERIFY_SIGNUP_OTP_FAIL,
                    payload: error
                });
            })
    }
}

// export const loginViaOtp = (userData, router) => {
//     // secureLocalStorage.clear()
//     let requestBody = {
//         grant_type: 'password',
//         password: "123",
//         is_email: '0',
//         email: "",
//         is_username: '0',
//         mobile: secureLocalStorage.getItem("verify_user")?.data?.mobile,
//         is_otp: 1,
//         client_secret: client_secret,
//         client_id: client_id,
//         otp: userData.otp,
//     };
//
//     return async (dispatch) => {
//         dispatch({
//             type: LOADING,
//         });
//         await reposWebApi.post('/customer/login', qs.stringify(requestBody), config)
//             .then(async response => {
//                 if (response.data.expires_in === 36000) {
//                     secureLocalStorage.setItem('user', JSON.stringify(response.data));
//                     secureLocalStorage.setItem('access_token', response.data.access_token);
//                     secureLocalStorage.setItem('refresh_token', response.data.refresh_token);
//
//                     await dispatch(details())
//                     dispatch({
//                         type: SESSION_LOGIN,
//                         payload: response.data
//                     });
//                     router.history.push('/dashboard');
//                 } else {
//
//                     await dispatch(details())
//                 }
//             }).catch(error => {
//                 if (error?.response?.status === 401) {
//                     dispatch({
//                         type: SESSION_EXPIRED,
//                     })
//                 } else if (error?.response?.status === 406) {
//                     dispatch({
//                         type: LOADING_FALSE,
//                     });
//                     if (requestBody.is_otp === 1) {
//                         dispatch({
//                             type: LOADING_FALSE,
//                         });
//                         if (error?.response?.data?.exception) {
//                             alert(error?.response?.data?.exception);
//                         } else {
//                             alert('Invalid OTP')
//                         }
//                     } else if (error?.response?.data?.exception) {
//                         alert(error?.response?.data?.exception)
//                     } else {
//                         alert(error?.response?.data?.Authorization)
//                         dispatch({
//                             type: SESSION_LOGIN_ERROR,
//                             payload: error
//                         });
//                     }
//                 } else {
//                     dispatch({
//                         type: SESSION_LOGIN_ERROR,
//                         payload: error
//                     });
//                 }
//             })
//     }
// }

export const raiseComplaint = () => {
    return async (dispatch) => {
        dispatch({
            type: LOADING,
        });
        await reposWebApi.get('/customer/customer_issue_category', {
            headers: {
                Authorization: 'Bearer ' + secureLocalStorage.getItem("access_token")
            }
        }).then(res => {
            dispatch({
                type: COMPLAINT,
                payload: res?.data?.data
            })
        }).catch(error => {
            if (error?.response?.status === 401) {
                dispatch(refreshToken())
                dispatch(raiseComplaint())
            }
        });
    }
};

export const getReferral = (data,fetchMoreData) => {
    return async (dispatch) => {
        if (!fetchMoreData) {
            dispatch({
                type: LOADING,
                payload: false
            });
        }
        await reposWebApi.get('/customer/referral_detail', {
            headers: {
                Authorization: 'Bearer ' + secureLocalStorage.getItem("access_token")
            },
            params:data
        }).then(res => {
            dispatch({
                type: REFERRAL,
                payload: res?.data?.data
            })
        }).catch(error => {
            if (error?.response?.status === 401) {
                dispatch(refreshToken())
                dispatch(getReferral())
            }
        });
    }
};

export const subComplaint = (value) => {
    return async (dispatch) => {
        dispatch({
            type: LOADING,
        });
        await reposWebApi.get('/customer/customer_issue_subcategory', {
            headers: {
                Authorization: 'Bearer ' + secureLocalStorage.getItem("access_token")
            },
            params: {
                category: value
            }
        }).then(res => {
            dispatch({
                type: SUB_COMPLAINT,
                payload: res?.data?.data
            })
        }).catch(error => {
            if (error?.response?.status === 401) {
                dispatch(refreshToken())
                dispatch(subComplaint())
            }
        });
    }
};

export const complaintList = (formData, fetchMoreData) => {
    return async (dispatch) => {
        if (!fetchMoreData) {
            dispatch({
                type: LOADING,
            });
            dispatch(clearComplaintData())
        }
        await reposWebApi.get('/customer/get_complaint_list ', {
            headers: {
                Authorization: 'Bearer ' + secureLocalStorage.getItem("access_token")
            },
            params: formData
        }).then(res => {
            dispatch({
                type: COMPLAINT_LIST,
                payload: res?.data?.data
            })
        }).catch(error => {
            if (error?.response?.status === 401) {
                dispatch(refreshToken())
                dispatch(complaintList(formData))
            }
        });
    }
};

export const createRaiseComplaint = (formData, enqueueSnackbar, history) => {
    return (dispatch) => {
        dispatch({
            type: LOADING,
        });
        reposWebApi.post('/customer/v2/raise_complaint', qs.stringify(formData), {
            headers: {
                Authorization: 'Bearer ' + secureLocalStorage.getItem("access_token")
            },
        }).then(response => {
            if (response.status === 201) {
                enqueueSnackbar('Complaint registered successfully', {
                    variant: 'success', anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right'
                    }
                });
            }
            history.push('/Help')
        }).catch(error => {
            dispatch({
                type: RAISE_COMPLAINT_FAILED,
            });
            enqueueSnackbar("please enter all the fields.", {
                variant: 'error', anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right'
                }
            });
        })
    }
};

export const addReferral = (params, enqueueSnackbar, history) => {

    return (dispatch) => {
        dispatch({
            type: LOADING,
        });
        reposWebApi.post('/customer/referral_detail', qs.stringify(params), {
            headers: {
                Authorization: 'Bearer ' + secureLocalStorage.getItem("access_token")
            }
        })
            .then(response => {
                dispatch({
                    type: ADD_REFERRAL,
                    payload: response?.data?.data
                })
                enqueueSnackbar(response?.data?.data?.msg, {
                    variant: 'success', anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right'
                    }
                });
                history.goBack()
                // dispatch(getReferral({ limit: 5, offset: 0 }));
            })
            .catch(error => {

                if (error?.response?.status === 406) {
                    enqueueSnackbar(error?.response?.data?.data, {
                        variant: 'info', anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        }
                    });
                }
                if (error?.response?.status === 502) {
                    enqueueSnackbar("something went wrong", {
                        variant: 'error', anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        }
                    });
                }
                if (error?.response?.status === 500) {
                    enqueueSnackbar("Invalid values", {
                        variant: 'error', anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        }
                    });
                }
            })
    }
};

export const clearComplaintData = () => {

    return (dispatch) => {
        dispatch({
            type: CLEAR_COMPLAIN_DATA,
        })
    }

};


export const versionControl = () => {
    return async (dispatch) => {
        await reposWebApi.get('/utilities/get_version_web', {}).then(res => {
            if ((decryption(res?.data?.data?.customer_version))!== appVersion) {
                alert("New app version detected please refresh the page");
                window.location.reload();
                appVersion = decryption(res?.data?.data?.customer_version);
            }
        }).catch(error => {

        });
    }
}

export const refreshOutstanding = () => {
    if (abortsignal) {
        abortsignal.abort();
    }

    abortsignal = new AbortController();
    const signal = abortsignal.signal;

    return async (dispatch) => {
        await reposWebApi.get('/customer/sub_detail/', {
            headers: {
                Authorization: 'Bearer ' + secureLocalStorage.getItem("access_token")
            },
            signal: signal,
        }).then(res => {
            // secureLocalStorage.setItem('userDetails', JSON.stringify(res?.data));
            // secureLocalStorage.setItem('notification', parseInt(0));
            dispatch({
                type: REFRESH,
                payload: res?.data?.data
            })
        }).catch(error => {
            if (error?.response?.status === 401) {
                dispatch(refreshToken())
            }
            if (error?.response?.state === 502 || error?.response?.state === 504) {
                dispatch(logout());
            }
        });
    }
};

export const fleetVanAccount = (data, fetchMoreData) => {
    return async (dispatch) => {
        if (!fetchMoreData) {
            dispatch({
                type: LOADING,
                payload: false
            });
        }
        await reposWebApi.get('/customer/fleet_account_list', {
            headers: {
                Authorization: 'Bearer ' + secureLocalStorage.getItem("access_token")
            },
            params:data
        }).then(res => {
            dispatch({
                type: FLEET_ACCOUNT,
                payload: res?.data?.data
            })
        }).catch(error => {
            if (error?.response?.status === 401) {
                dispatch(refreshToken())
                dispatch(fleetVanAccount())
            }
        });
    }
};


export const vanHistory = (data,fetchMoreData) => {
    return async (dispatch) => {
        if (!fetchMoreData) {
            dispatch({
                type: LOADING,
                payload: false
            });
        }
        await reposWebApi.get('/customer/fleet_order_list', {
            headers: {
                Authorization: 'Bearer ' + secureLocalStorage.getItem("access_token")
            },
            params:data
        }).then(res => {
            dispatch({
                type: VAN_HISTORY,
                payload: res?.data?.data
            })
        }).catch(error => {
            if (error?.response?.status === 401) {
                dispatch(refreshToken())
                dispatch(vanHistory())
            }
        });
    }
};

export const addVanAccount = (params, enqueueSnackbar) => {

    return (dispatch) => {
        dispatch({
            type: LOADING,
        });
        reposWebApi.post('/customer/create_update_fleet_account', qs.stringify(params), {
            headers: {
                Authorization: 'Bearer ' + secureLocalStorage.getItem("access_token")
            }
        })
            .then(response => {
                dispatch({
                    type: ADD_VAN_ACCOUNT,
                    payload: response?.data?.data
                })
                enqueueSnackbar(response?.data?.data?.msg, {
                    variant: 'success', anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right'
                    }
                });
                window.location.reload();
            })
            .catch(error => {

                if (error?.response?.status === 406) {
                    enqueueSnackbar(error?.response?.data?.data?.msg, {
                        variant: 'info', anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        }
                    });
                }
                if (error?.response?.status === 502) {
                    enqueueSnackbar("something went wrong", {
                        variant: 'error', anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        }
                    });
                }
                if (error?.response?.status === 500) {
                    enqueueSnackbar("Invalid values", {
                        variant: 'error', anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        }
                    });
                }
                dispatch({
                    type: ADD_VAN_ACCOUNT_ERROR,
                    payload: true
                });
            })
    }
};


export const getFleetOtp = (mobile, c_code, enqueueSnackbar) => {
    const requestBody = {
        mobile: mobile,
    };
    return (dispatch) => {
        reposWebApi.post('/customer/send_otp_fleet_account', qs.stringify(requestBody),{
        headers: {
            Authorization: 'Bearer ' + secureLocalStorage.getItem("access_token")
        }
        })
            .then(response => {
                if (response?.status === 200) {
                    // setLoading(false);
                    dispatch({
                        type: GET_OTP_FOR_FLEET,
                        payload: true
                    });

                    enqueueSnackbar(response?.data?.data, {
                        variant: 'success', anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        }
                    })
                    dispatch(login(enqueueSnackbar))
                }
            })
            .catch(error => {
                if (error?.response?.status === 401) {
                    dispatch({
                        type: SESSION_EXPIRED,
                    })
                }
                if (error?.response?.status === 429) {

                    const matches = error?.response?.data?.data.match(/\d+/);
                    const number = matches[0];

                    dispatch({
                        type: GET_OTP_FOR_FLEET_MSG,
                        payload: number
                    });

                    enqueueSnackbar(error?.response?.data?.data, {
                        variant: 'warning', anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        }
                    })
                }
                if (error?.response?.status === 406) {
                    enqueueSnackbar(error.response.data.data, {
                        variant: 'error', anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        }
                    });
                    dispatch({
                        type: GET_OTP_FOR_FLEET_ERROR,
                        payload: true
                    });
                }
            })
    }
};

export const verifyFleetOtp = (requestBody, enqueueSnackbar , setHasError , setOtp) => {

    return (dispatch) => {
        dispatch({
            type: FORGOT_LOADING,
        });
        reposWebApi.post('/customer/verify_fleet_account_otp', qs.stringify(requestBody),{
            headers: {
                Authorization: 'Bearer ' + secureLocalStorage.getItem("access_token")
            }
            })
            .then(response => {
                dispatch({
                    type: VERIFY_FLEET_OTP,
                    payload: response?.data?.data
                });

                enqueueSnackbar(response?.data?.data, {
                    variant: 'success', anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right'
                    }
                });
                // dispatch(fleetVanAccount())
                window.location.reload();

            })
            .catch(error => {
                if (error?.response?.status === 401) {
                    dispatch({
                        type: SESSION_EXPIRED,
                    })
                }
                if (error?.response?.status === 406) {
                    enqueueSnackbar("invalid OTP", {
                        variant: 'error', anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        }
                    });
                    setHasError(true); // Set error state
                    setOtp(["", "", "", ""]); // Clear OTP fields
                    dispatch({
                        type: VERIFY_FLEET_OTP_FAIL,
                    })
                }
                dispatch({
                    type: VERIFY_FLEET_OTP_FAIL,
                    payload: error
                });
            })
    }
}

export const placeOrder = (params, enqueueSnackbar, history) => {

    return (dispatch) => {
        dispatch({
            type: LOADING,
        });
        reposWebApi.post('/customer/fleet_payment_order_creation', qs.stringify(params), {
            headers: {
                Authorization: 'Bearer ' + secureLocalStorage.getItem("access_token")
            }
        })
            .then(response => {
                dispatch({
                    type: PLACE_ORDER,
                    payload: response?.data?.data
                })
                enqueueSnackbar(response?.data?.data, {
                    variant: 'success', anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right'
                    }
                });
                // history.goBack()
                // dispatch(fleetVanAccount({ limit: 5, offset: 0 }));
                // window.location.reload();
            })
            .catch(error => {

                if (error?.response?.status === 406) {
                    enqueueSnackbar(error?.response?.data?.data?.msg, {
                        variant: 'info', anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        }
                    });
                }
                if (error?.response?.status === 502) {
                    enqueueSnackbar("something went wrong", {
                        variant: 'error', anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        }
                    });
                }
                if (error?.response?.status === 500) {
                    enqueueSnackbar("Invalid values", {
                        variant: 'error', anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        }
                    });
                }
            })
    }
};