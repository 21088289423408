import {nodeStaging, reposWebApi} from "./customerAPI";
import qs from "querystring";
import {getAsset, logout, refreshToken} from "./sessionAction";
import secureLocalStorage from "react-secure-storage";

export const ASSET_OUT_OF_SERVICE = " ASSET_OUT_OF_SERVICE";
export const GET_ASSETS = "GET_ALL_ASSETS";
export const CLEAR_ASSET_DATA = "CLEAR_ASSET_DATA";
export const GET_ASSETS_FAIL = "GET_ALL_ASSETS_FAIL";
export const ASSET_LOADING = "ASSET_LOADING";
export const ASSET_TYPE_COUNT = "ASSET_TYPE_COUNT";
export const CREATING_NEW_ASSET = "CREATING_NEW_ASSET";
export const CREATE_ASSET = "CREATE_NEW_ASSET";
export const GET_SINGLE_ASSET = "GET_SINGLE_ASSET";
export const EDIT_ASSET = "EDIT_ASSET";
export const CHANGE_ASSET_PASSWORD = "CHANGE_ASSET_PASSWORD";
export const EDIT_ASSET_FAIL = "EDIT_ASSET_FAIL";
export const CREATE_ASSET_FAIL = "CREATING_NEW_ASSET_FAIL";
export const DELETING_ASSET = "DELETE_ASSET_LOADING";
export const DELETE_ASSET = "DELETE_ASSET";
export const DELETE_ASSET_FAIL = "DELETE_ASSET_FAIL";
export const GET_DISPENSE_REPORTS = "GET_DISPENSE_REPORTS";
export const GET_DISPENSE_REPORTS_FAIL = "GET_DISPENSE_REPORTS_FAIL";
export const CREATE_DISPENSE = "CREATE_DISPENSE";
export const CREATE_DISPENSE_FAIL = "CREATE_DISPENSE_FAIL";
export const GET_REFILL_REPORTS = "GET_REFILL_REPORTS";
export const GET_REFILL_REPORTS_FAIL = "GET_REFILL_REPORTS_FAIL";
export const SESSION_EXPIRED = "SESSION_EXPIRED";
export const GET_RPP_LIST = "GET_RPP_LIST";
export const GET_SINGLE_RPP = "GET_SINGLE_RPP";
export const GET_DATE = "GET_DATE";
export const GET_CITY_ASSET = "GET_CITY_ASSET";
export const IS_CITY_LOADING = 'IS_CITY_LOADING';
export const ASSET_TYPE_COUNT_LOADING = "ASSET_TYPE_COUNT_LOADING";
export const GET_CITY_FAIL = "GET_CITY_FAIL";
export const ASSET_LOCATION = 'ASSET_LOCATION';
export const GET_ASSET_LIST = 'GET_ASSET_LIST';
export const DATUM_HEALTH_LOADING = 'DATUM_HEALTH_LOADING';
export const DATUM_HEALTH = 'DATUM_HEALTH';
export const PLACE_ORDER_PAGE = 'PLACE_ORDER_PAGE';
export const GET_DATUM_GRAPH_DETAILS = "GET_DATUM_GRAPH_DETAILS";
export const GET_DATUM_GRAPH_DETAILS_FAIL = 'GET_DATUM_GRAPH_DETAILS_FAIL';
export const GET_REFILL_REPORTS_NOT_FOUND = 'GET_REFILL_REPORTS_NOT_FOUND';
export const GET_ASSET_LIST_FAIL = 'GET_ASSET_LIST_FAIL';
export const GET_SUBASSET_GRAPH_DETAILS = 'GET_SUBASSET_GRAPH_DETAILS';
export const GET_SUBASSET_GRAPH_DETAILS_FAIL = 'GET_SUBASSET_GRAPH_DETAILS_FAIL';
export const GET_ANALYTICS_REPORT = 'GET_ANALYTICS_REPORT';
export const GET_ANALYTICS_REPORT_FAIL = 'GET_ANALYTICS_REPORT_FAIL';
export const GET_SUB_ANALYTICS_REPORT = 'GET_SUB_ANALYTICS_REPORT';
export const GET_SUB_ANALYTICS_REPORT_FAIL = 'GET_SUB_ANALYTICS_REPORT_FAIL';
export const SUB_ASSET_ANALYTICS_LOADING = 'SUB_ASSET_ANALYTICS_LOADING';
export const SET_FORM_DATA = 'SET_FORM_DATA';
export const GET_MAIL_REPORTS = "GET_MAIL_REPORTS";
export const GET_MAIL_REPORTS_FAIL = "GET_MAIL_REPORTS_FAIL";
export const GET_DOWNLOAD_REPORTS_FAIL = "GET_DOWNLOAD_REPORTS_FAIL";
export const GET_DOWNLOAD_REPORTS = "GET_DOWNLOAD_REPORTS";
export const GET_DIP_LOG = 'GET_DIP_LOG';
export const GET_DIP_LOG_FAIL = 'GET_DIP_LOG_FAIL';


let abortsignal = '';
export const getAllAssets = (search, pickup, asset_type, isNewOrder,limit,offset) => {
    if (abortsignal) {
        abortsignal.abort();
    }

    abortsignal = new AbortController();
    const signal = abortsignal.signal;

    let queryParams = {};
    queryParams.search = search;
    queryParams.asset_state = pickup;
    queryParams.asset_type = asset_type;
    queryParams.limit = limit;
    queryParams.offset = offset

    return (dispatch) => {
        dispatch({
            type: ASSET_LOADING,
        });
        reposWebApi
            .get("/customer/v2/get_customer_assets", {
                headers: {
                    Authorization: "Bearer " + secureLocalStorage.getItem("access_token"),
                },
                params: queryParams,
                signal: signal,
            })
            .then((response) => {
                var i;
                for (i = 0; i < response?.data?.data?.length; ++i) {
                    if (response?.data?.data.results[i]?.asset_type === 1) {
                        secureLocalStorage.setItem("has_datum", true);
                        break;
                    } else {
                        secureLocalStorage.setItem("has_datum", false);
                    }
                }

                if (response?.status === 200) {
                    secureLocalStorage.setItem("assetCount", JSON.stringify(response?.data));
                    dispatch({
                        type: GET_ASSETS,
                        payload: response?.data?.data,
                    });
                }
            }).catch(async (error) => {
            if (error?.response?.status === 401) {
                await dispatch(refreshToken());
                setTimeout(dispatch(getAllAssets()), 3000);
            }
            if (error?.response?.state === 502 || error?.response?.state === 504) {
                dispatch(logout());
            } else
                dispatch({
                    type: GET_ASSETS_FAIL,
                });
        });
    };
    // }
};

export const createAsset = (formData, enqueueSnackbar, history) => {
    return (dispatch) => {
        dispatch({
            type: CREATING_NEW_ASSET,
        });
        reposWebApi
            .post("/customer/create/new/asset", qs.stringify(formData), {
                headers: {
                    Authorization: "Bearer " + secureLocalStorage.getItem("access_token"),
                },
            })
            .then((response) => {
                enqueueSnackbar(response.data.data, {
                    variant: 'success'
                });
                dispatch({
                    type: CREATE_ASSET,
                    payload: response?.data?.data,
                });
                history.push('/newassets')
                // dispatch(getAllAssets());
            })
            .catch(async (error) => {
                if (error?.response?.status === 401) {
                    await dispatch(refreshToken());
                    dispatch(createAsset());
                } else if (error?.response?.status === 400) {
                    enqueueSnackbar(error?.response?.data?.data, {
                        variant: 'error'
                    });
                    dispatch({
                        type: CREATE_ASSET_FAIL,
                        payload: true,
                    });
                } else
                    dispatch({
                        type: CREATE_ASSET_FAIL,
                        payload: true,
                    });
            });
    };
};


export const getAssetAnalytics = (formData, enqueueSnackbar, fetchMoreData) => {

    if (abortsignal) {
        abortsignal.abort();
    }

    abortsignal = new AbortController();
    const signal = abortsignal.signal;

    return (dispatch) => {
        if (!fetchMoreData) {
            dispatch({
                type: ASSET_LOADING,
            });
        }
        reposWebApi.get("/customer/get_assets_analytics", {
            headers: {
                Authorization: "Bearer " + secureLocalStorage.getItem("access_token"),
            },
            params: formData,
        })
            .then((response) => {
                dispatch({
                    type: GET_ANALYTICS_REPORT,
                    payload: response?.data?.data,
                });
            })
            .catch(async (error) => {
                if (error?.response?.status === 401) {
                    await dispatch(refreshToken());
                    dispatch(getAssetAnalytics());
                } else {
                    enqueueSnackbar("Something Went Wrong..!", {
                        variant: 'error',
                        horizontal: 'center'
                    });
                    dispatch({
                        type: GET_ANALYTICS_REPORT_FAIL,
                    });
                }

            });
    };
};


export const getSubAssetAnalytics = (formData, fetchMoreData) => {


    if (abortsignal) {
        abortsignal.abort();
    }

    abortsignal = new AbortController();
    const signal = abortsignal.signal;

    return (dispatch) => {
        // if (!fetchMoreData) {
        dispatch({
            type: SUB_ASSET_ANALYTICS_LOADING,
        });
        // }
        reposWebApi.get("/customer/get_sub_asset_analytics", {
            headers: {
                Authorization: "Bearer " + secureLocalStorage.getItem("access_token"),
            },
            params: formData,
        })
            .then((response) => {
                dispatch({
                    type: GET_SUB_ANALYTICS_REPORT,
                    payload: response?.data?.data,
                });
            })
            .catch(async (error) => {
                if (error?.response?.status === 401) {
                    await dispatch(refreshToken());
                    dispatch(getSubAssetAnalytics());
                } else {

                    dispatch({
                        type: GET_SUB_ANALYTICS_REPORT_FAIL,
                    });
                }

            });
    };
};

export const getSingleAsset = (asset) => {
    return (dispatch) => {
        dispatch({
            type: GET_SINGLE_ASSET,
            payload: asset,
        });
    };
};

export const getDate = (date) => {
    return (dispatch) => {
        dispatch({
            type: GET_DATE,
            payload: date,
        });
    };
};

export const getSingleRPP = (rpp) => {
    return (dispatch) => {
        dispatch({
            type: GET_SINGLE_RPP,
            payload: rpp,
        });
    };
};

export const getRPPList = (value) => {
    return (dispatch) => {
        dispatch({
            type: GET_RPP_LIST,
            payload: value,
        });
    };
};

export const assetLocation = (value) => {
    return (dispatch) => {
        dispatch({
            type: ASSET_LOCATION,
            payload: value,
        });
    };
}

export const editAsset = (formData, enqueueSnackbar, history,handleClose) => {

    return (dispatch) => {
        dispatch({
            type: CREATING_NEW_ASSET,
        });
        reposWebApi
            .post("/customer/update/asset", qs.stringify(formData), {
                headers: {
                    Authorization: "Bearer " + secureLocalStorage.getItem("access_token"),
                },
            })
            .then((response) => {
                enqueueSnackbar(formData?.asset_type === 1 ? "RFD information update successfully" : response.data.data, {
                    variant: 'success'
                });
                dispatch({
                    type: EDIT_ASSET,
                    payload: response?.data?.data,
                });
                // handleClose()
                    history.push('/newasset')
                // dispatch(getAllAssets());
            })
            .catch(async (error) => {
                if (error?.response?.status === 401) {
                    await dispatch(refreshToken());
                    dispatch(editAsset());
                }
                if (error?.response?.status === 400) {
                    enqueueSnackbar(error?.response?.data?.data, {
                        variant: 'error'
                    });
                    dispatch({
                        type: EDIT_ASSET_FAIL,
                        payload: true,
                    });
                } else
                dispatch({
                    type: EDIT_ASSET_FAIL,
                    payload: true,
                });
            });
    };
};

export const changeCredentials = (username, password, enqueueSnackbar) => {
    const requestBody = {
        username,
        password
    };
    return (dispatch) => {
        dispatch({
            type: CREATING_NEW_ASSET,
        });
        reposWebApi
            .post("/customer/change_datum_credentials", qs.stringify(requestBody), {
                headers: {
                    Authorization: "Bearer " + secureLocalStorage.getItem("access_token"),
                },
            })
            .then((response) => {
                enqueueSnackbar(response.data.data, {
                    variant: 'success',
                    horizontal: 'center'
                });
                dispatch({
                    type: CHANGE_ASSET_PASSWORD,
                    payload: response?.data,
                });
                dispatch(getAllAssets());
            })
            .catch(async (error) => {
                if (error?.response?.status === 401) {
                    await dispatch(refreshToken());
                    dispatch(changeCredentials());
                }
                if (error?.response?.status === 400) {
                    alert("Invalid Pin Code");
                    dispatch({
                        type: EDIT_ASSET_FAIL,
                        payload: true,
                    });
                } else
                    enqueueSnackbar('Something went wrong..!, Please Try Again later', {
                        variant: 'error',
                        horizontal: 'center'
                    });
                dispatch({
                    type: EDIT_ASSET_FAIL,
                    payload: true,
                });
            });
    };
};

export const mailDatumReport = (formData, enqueueSnackbar,model) => {
    if (abortsignal) {
        abortsignal.abort();
    }


    abortsignal = new AbortController();
    const signal = abortsignal.signal;

    return (dispatch) => {
            dispatch({
                type: ASSET_LOADING,
            });
        reposWebApi
            .get(model === 'X' ?"/customer/datumx_report_mail" :"/customer/datum/dispense/report/mail" , {
                headers: {
                    Authorization: "Bearer " + secureLocalStorage.getItem("access_token"),
                },
                signal: signal,
                params: formData,
            })
            .then((response) => {
                if (response?.status === 200) {

                    dispatch({
                        type: GET_MAIL_REPORTS,
                        payload: response?.data?.data,
                    });

                    enqueueSnackbar("Report Sent: your report has been emailed successfully! ", {
                        variant: 'success',
                        horizontal: 'right',
                        vertical:'top'
                    });

                }
            }).catch((error) => {
            if (error.response?.status === 401) {
                enqueueSnackbar("Session Expired..!", {
                    variant: 'warning',
                    horizontal: 'center'
                });
                dispatch(refreshToken());
                dispatch(getDispenseReports(formData, enqueueSnackbar));
            } else if (error.response?.status >= 500) {
                enqueueSnackbar("Something went wrong..! Please Try Again later", {
                    variant: 'error',
                    horizontal: 'center'
                });
                dispatch({
                    type: GET_MAIL_REPORTS_FAIL,
                    payload: true,
                });
            }
        });
    };
}

export const getDispenseReports = (formData, enqueueSnackbar, scrollToPreview, fetchMoreData) => {
    if (abortsignal) {
        abortsignal.abort();
    }

    const queryParams = {
        limit: 5,
        offset: 0
    };

    abortsignal = new AbortController();
    const signal = abortsignal.signal;

    return (dispatch) => {
        if (!fetchMoreData) {
            dispatch({
                type: ASSET_LOADING,
            });
            dispatch(clearAssetData())
        }
        reposWebApi
            .get("/customer/get_datum_dispense_refill", {
                headers: {
                    Authorization: "Bearer " + secureLocalStorage.getItem("access_token"),
                },
                signal: signal,
                params: formData,
            })
            .then((response) => {
                if (response?.status === 200) {

                    dispatch({
                        type: GET_DISPENSE_REPORTS,
                        payload: response?.data?.data,
                        // .data.results,
                    });
                    scrollToPreview();

                }
            }).catch((error) => {
            if (error.response?.status === 401) {
                enqueueSnackbar("Session Expired..!", {
                    variant: 'warning',
                    horizontal: 'center'
                });
                dispatch(refreshToken());
                dispatch(getDispenseReports(formData, enqueueSnackbar, scrollToPreview));
            } else if (error.response?.status >= 500) {
                enqueueSnackbar("Something went wrong..! Please Try Again later", {
                    variant: 'error',
                    horizontal: 'center'
                });
                dispatch({
                    type: GET_DISPENSE_REPORTS_FAIL,
                    payload: true,
                });
            }
        });
    };

};

export const getRefillReports = (formData, enqueueSnackbar, fetchMoreData) => {

    if (abortsignal) {
        abortsignal.abort();
    }

    abortsignal = new AbortController();
    const signal = abortsignal.signal;

    return (dispatch) => {
        if (!fetchMoreData) {
            dispatch({
                type: ASSET_LOADING,
            });
        }
        reposWebApi.get("/customer/asset/refill/v2/list", {
            headers: {
                Authorization: "Bearer " + secureLocalStorage.getItem("access_token"),
            },
            signal: signal,
            params: formData,
        })
            .then((response) => {
                dispatch({
                    type: GET_REFILL_REPORTS,
                    payload: response?.data?.data,
                });
            })
            .catch(async (error) => {
                if (error?.response?.status === 401) {
                    await dispatch(refreshToken());
                    dispatch(getRefillReports());
                } else if (error?.response?.status === 404) {
                    enqueueSnackbar(error?.response?.data?.data?.error, {
                        variant: 'info',
                        horizontal: 'center'
                    });
                    dispatch({
                        type: GET_REFILL_REPORTS_NOT_FOUND
                    });
                } else {
                    enqueueSnackbar("Something Went Wrong..!", {
                        variant: 'error',
                        horizontal: 'center'
                    });
                    dispatch({
                        type: GET_REFILL_REPORTS_FAIL,
                        payload: true,
                    });
                }
            });
    };
};

export const deleteAssetAction = (assetId) => {
    const requestBody = {
        asset_id: assetId,
    };
    return (dispatch) => {
        dispatch({
            type: ASSET_LOADING,
        });
        reposWebApi
            .post("/customer/delete/asset", qs.stringify(requestBody), {
                headers: {
                    Authorization: "Bearer " + secureLocalStorage.getItem("access_token"),
                },
            })
            .then((response) => {
                dispatch({
                    type: DELETE_ASSET,
                    payload: response?.data?.data,
                });
                dispatch(getAllAssets());
            })
            .catch(async (error) => {
                if (error?.response?.status === 401) {
                    await dispatch(refreshToken());
                    dispatch(deleteAssetAction());
                }
                dispatch({
                    type: DELETE_ASSET_FAIL,
                    payload: error,
                });
            });
    };
};

export const createDispense = (dispenseEntry) => {
    var userData = JSON.parse(secureLocalStorage.getItem("user"));

    const requestBody = {
        quantity: dispenseEntry.quantity,
        dispensed_to: dispenseEntry.vehicle,
        asset_id: dispenseEntry.asset_id,
        dispensed_by: dispenseEntry.executive,
        customer_id: userData.user_details.id,
    };

    return (dispatch) => {
        dispatch({
            type: ASSET_LOADING,
        });
        reposWebApi
            .post("/order/place_customer_order", qs.stringify(requestBody), {
                headers: {
                    Authorization: "Bearer " + secureLocalStorage.getItem("access_token"),
                },
            })
            .then((response) => {
                dispatch({
                    type: CREATE_DISPENSE,
                    payload: response.status,
                });
            })
            .catch(async (error) => {
                if (error?.response?.status === 401) {
                    await dispatch(refreshToken());
                    dispatch(createDispense());
                }
                dispatch({
                    type: CREATE_DISPENSE_FAIL,
                    payload: error,
                });
            });
    };
};


export const AssetTypeCount = (searchKey, asset_state, isPlaceOrder, setAsset_type) => {
    if (abortsignal) {
        abortsignal.abort();
    }

    abortsignal = new AbortController();
    const signal = abortsignal.signal;

    return (dispatch) => {
        dispatch({
            type: ASSET_TYPE_COUNT_LOADING,
        });
        reposWebApi
            .get("customer/asset_type_count", {
                headers: {
                    Authorization: "Bearer " + secureLocalStorage.getItem("access_token"),
                },
                signal: signal,
            })
            .then((response) => {
                secureLocalStorage.setItem('asset_type', JSON.stringify(response))
                secureLocalStorage.setItem('datum_count', JSON.stringify(response?.data?.data[0]?.asset_type_count))
                dispatch({
                    type: ASSET_TYPE_COUNT,
                    payload: response?.data?.data,
                });
                if (response?.data?.data[0]?.asset_type_count !== 0) {
                    setAsset_type(1);
                    !isPlaceOrder &&
                    dispatch(getAsset(searchKey, asset_state,
                        (response?.data?.data[0]?.asset_type__type === 1
                            && response?.data?.data[0]?.asset_type_count !== 0) ? 1 : undefined, undefined))

                } else {
                    dispatch(getAsset(searchKey, asset_state, undefined, undefined))
                }
            })
            .catch(async (error) => {
                if (error?.response?.status === 401) {
                    await dispatch(refreshToken());
                    dispatch(AssetTypeCount());
                }
                if (error?.response?.state === 502 || error?.response?.state === 504) {
                    dispatch(logout());
                } else
                    dispatch({
                        type: GET_ASSETS_FAIL,
                    });
            });
    };
    // }
};

export const getCity = (pickup) => {
    return (dispatch) => {
        dispatch({
            type: IS_CITY_LOADING
        });
        reposWebApi
            .get("customer/list_customerasset_city", {
                headers: {
                    Authorization: "Bearer " + secureLocalStorage.getItem("access_token"),
                },
                params: {
                    movable: pickup
                }
            })
            .then((response) => {
                dispatch({
                    type: GET_CITY_ASSET,
                    payload: response?.data?.data,
                });
            })
            .catch(async (error) => {
                if (error?.response?.status === 401) {
                    await dispatch(refreshToken());
                    dispatch(getCity());
                }
                if (error?.response?.state === 502 || error?.response?.state === 504) {
                    dispatch(logout());
                } else
                    dispatch({
                        type: GET_CITY_FAIL,
                    });
            });
    };
}

//*** Asset Out of Service api. ****//

export const setAssetOutOfervice = (asset_id, value) => {
    const requestBody = {
        is_active: asset_id,
        asset_id: value,
    };

    return (dispatch) => {
        dispatch({
            type: ASSET_LOADING,
        });
        reposWebApi
            .post("/customer/asset_out_of_service", qs.stringify(requestBody), {
                headers: {
                    Authorization: "Bearer " + secureLocalStorage.getItem("access_token"),
                },
            })
            .then((response) => {
                dispatch({
                    type: ASSET_OUT_OF_SERVICE,
                    payload: response.status,
                });
            })
            .catch(async (error) => {
                if (error?.response?.status === 401) {
                    await dispatch(refreshToken());
                    dispatch(setAssetOutOfervice());
                }
                dispatch({
                    type: CREATE_DISPENSE_FAIL,
                    payload: error,
                });
            });
    };
};

export const getDatumHealth = (param) => {

    if (abortsignal) {
        abortsignal.abort();
    }

    abortsignal = new AbortController();
    const signal = abortsignal.signal;

    return (dispatch) => {
        dispatch({
            type: DATUM_HEALTH_LOADING,
        });
        reposWebApi.get("customer/get_datum_health", {
            headers: {
                Authorization: "Bearer " + secureLocalStorage.getItem("access_token"),
            },
            signal: signal,
            params: {
                asset_id: param
            },
        })
            .then((response) => {
                dispatch({
                    type: DATUM_HEALTH,
                    payload: response.data,
                });
            })
            .catch(async (error) => {
                if (error?.response?.status === 401) {
                    await dispatch(refreshToken());
                    dispatch(getDatumHealth());
                } else {

                }
            });
    }
};

export const formData = (data) => {
    return (dispatch) => {
        dispatch({
            type: SET_FORM_DATA,
            payload: data
        })
    }
}


//  **************** Datum Graph List API ************************
export const getDatumGraphDetails = (formData, loadChart, datum_model) => {
    if (abortsignal) {
        abortsignal.abort();
    }

    abortsignal = new AbortController();
    const signal = abortsignal.signal;

    return async (dispatch) => {
        dispatch({
            type: ASSET_LOADING,
        })
        await  reposWebApi.get(datum_model === 'X' ?  '/customer/get_graph_datumx' : '/customer/graph_refill_dispense', {
            headers: {
                Authorization: 'Bearer ' + secureLocalStorage.getItem("access_token")
            },
            // signal: signal,
            params: formData
        }).then(response => {
            dispatch({
                type: GET_DATUM_GRAPH_DETAILS,
                payload: response?.data?.data
            });
            loadChart(response?.data?.data);
        }).catch(async error => {
            if (error?.response?.status === 401) {
                await dispatch(refreshToken())
                dispatch(getDatumGraphDetails())
            } else dispatch({
                type: GET_DATUM_GRAPH_DETAILS_FAIL,
                payload: error
            })
        });
    }
};


export const getSubAssetGraphDetails = (formData, loadChart) => {
    if (abortsignal) {
        abortsignal.abort();
    }

    abortsignal = new AbortController();
    const signal = abortsignal.signal;

    return async (dispatch) => {
        dispatch({
            type: ASSET_LOADING,
        })
        await reposWebApi.post('/customer/graph_datum_dispense', qs.stringify(formData), {
            headers: {
                Authorization: 'Bearer ' + secureLocalStorage.getItem("access_token")
            }
        }).then(response => {
            dispatch({
                type: GET_SUBASSET_GRAPH_DETAILS,
                payload: response?.data?.data
            });
            loadChart(response?.data?.data);
        }).catch(async error => {
            if (error?.response?.status === 401) {
                await dispatch(refreshToken())
                dispatch(getSubAssetGraphDetails())
            } else dispatch({
                type: GET_SUBASSET_GRAPH_DETAILS_FAIL,
                payload: error
            })
        });
    }
};


export const getSubAssetList = () => {

    return async (dispatch) => {
        // dispatch({
        //     type: ASSET_LOADING,
        // })
        await reposWebApi.get('/customer/get_assets_list', {
            headers: {
                Authorization: 'Bearer ' + secureLocalStorage.getItem("access_token")
            },
        }).then(response => {
            dispatch({
                type: GET_ASSET_LIST,
                payload: response?.data?.data
            });
        }).catch(async error => {
            if (error?.response?.status === 401) {
                await dispatch(refreshToken())
                dispatch(getSubAssetList())
            } else dispatch({
                type: GET_ASSET_LIST_FAIL,
                payload: error
            })
        });
    }
};


export const editAssetIncharge = (formData, enqueueSnackbar, setAssetInchargeClose, setOpenDetails) => {
    return async (dispatch) => {
        dispatch({
            type: ASSET_LOADING,
        })
        await reposWebApi.post('/customer/edit_asset_incharge', qs.stringify(formData), {
            headers: {
                Authorization: 'Bearer ' + secureLocalStorage.getItem("access_token")
            }
        }).then(response => {
            enqueueSnackbar(response?.data?.data?.msg, {
                variant: 'success',
                horizontal: 'center'
            });
            setAssetInchargeClose()
            setOpenDetails(false);
            dispatch(getAllAssets());
        }).catch(async error => {
            if (error?.response?.status === 401) {
                await dispatch(refreshToken())
                dispatch(editAssetIncharge(formData, enqueueSnackbar, setAssetInchargeClose))
            }
            enqueueSnackbar("Something Went Wrong..!", {
                variant: 'error',
                horizontal: 'center'
            });
        });
    }
}

export const sendDespendedViaMail = (formData, enqueueSnackbar,) => {
    if (abortsignal) {
        abortsignal.abort();
    }

    abortsignal = new AbortController();
    const signal = abortsignal.signal;

    return async (dispatch) => {
        dispatch({
            type: ASSET_LOADING,
        })
        await reposWebApi.get('/customer/datum/dispense/report/mail', {
            headers: {
                Authorization: 'Bearer ' + secureLocalStorage.getItem("access_token")
            },
            signal: signal,
            params: formData
        }).then(response => {
            enqueueSnackbar(response?.data?.data, {
                variant: 'success',
                horizontal: 'center'
            });
        }).catch(async error => {
            if (error?.response?.status === 401) {
                await dispatch(refreshToken())
                dispatch(sendDespendedViaMail(formData, enqueueSnackbar))
            }
            enqueueSnackbar("Something Went Wrong..!", {
                variant: 'error',
                horizontal: 'center'
            });
        });
    }
}

export const sendRefillViaMail = (formData, enqueueSnackbar,) => {
    if (abortsignal) {
        abortsignal.abort();
    }

    abortsignal = new AbortController();
    const signal = abortsignal.signal;

    return async (dispatch) => {
        dispatch({
            type: ASSET_LOADING,
        })
        await reposWebApi.get('/customer/datum/dispense/report/mail', {
            headers: {
                Authorization: 'Bearer ' + secureLocalStorage.getItem("access_token")
            },
            signal: signal,
            params: formData
        }).then(response => {
            enqueueSnackbar(response?.data?.data, {
                variant: 'success',
                horizontal: 'center'
            });
        }).catch(async error => {
            if (error?.response?.status === 401) {
                await dispatch(refreshToken())
                dispatch(sendDespendedViaMail(formData, enqueueSnackbar))
            }
            enqueueSnackbar("Something Went Wrong..!", {
                variant: 'error',
                horizontal: 'center'
            });
        });
    }
}


export const graphDatumDispense = (formData, enqueueSnackbar,) => {
    if (abortsignal) {
        abortsignal.abort();
    }

    abortsignal = new AbortController();
    const signal = abortsignal.signal;

    return async (dispatch) => {
        dispatch({
            type: ASSET_LOADING,
        })
        await reposWebApi.get('/customer/graph_datum_dispense', {
            headers: {
                Authorization: 'Bearer ' + secureLocalStorage.getItem("access_token")
            },
            signal: signal,
            params: formData
        }).then(response => {
            enqueueSnackbar(response?.data?.data, {
                variant: 'success',
                horizontal: 'center'
            });
        }).catch(async error => {
            if (error?.response?.status === 401) {
                await dispatch(refreshToken())
                dispatch(sendDespendedViaMail(formData, enqueueSnackbar))
            }
            enqueueSnackbar("Something Went Wrong..!", {
                variant: 'error',
                horizontal: 'center'
            });
        });
    }
}

export const isAssetLoading = () => {
    return (dispatch) => {
        dispatch({
            type: ASSET_LOADING,
        })
    }
}

export const isNewOrderPage = (value) => {
    return (dispatch) => {
        dispatch({
            type: PLACE_ORDER_PAGE,
            payload: value
        })
    }
}
export const clearAssetData = () => {

    return (dispatch) => {
        dispatch({
            type: CLEAR_ASSET_DATA,
        })
    }

};

export const DownloadDatumReport = (formData, enqueueSnackbar) => {
    if (abortsignal) {
        abortsignal.abort();
    }

    abortsignal = new AbortController();
    const signal = abortsignal.signal;

    return (dispatch) => {
        dispatch({
            type: ASSET_LOADING,
        });

        reposWebApi
            .get("/customer/download_csv_to_pdf", {
                headers: {
                    Authorization: "Bearer " + secureLocalStorage.getItem("access_token"),
                },
                signal: signal,
                params: formData,
                responseType: 'blob',  // Important to handle file downloads
            })
            .then((response) => {
                if (response?.status === 200) {
                    // Create a URL for the blob (file data)
                    const url = window.URL.createObjectURL(new Blob([response.data]));
                    const link = document.createElement('a');
                    link.href = url;
                    
                    // Set filename based on your preference
                    link.setAttribute('download', `report_${formData.start_date}_to_${formData.end_date}.pdf`);
                    document.body.appendChild(link);
                    
                    // Trigger the download
                    link.click();
                    link.remove(); // Cleanup
                    
                    // Optionally dispatch success action (if needed)
                    dispatch({
                        type: GET_DOWNLOAD_REPORTS,
                        payload: response?.data?.data, // Modify payload according to your needs
                    });

                    enqueueSnackbar("Report Downloaded successfully!", {
                        variant: 'success',
                        horizontal: 'right',
                        vertical: 'top',
                    });
                }
            })
            .catch((error) => {
                if (error.response?.status === 401) {
                    enqueueSnackbar("Session Expired..!", {
                        variant: 'warning',
                        horizontal: 'center',
                    });
                    dispatch(refreshToken());
                    dispatch(getDispenseReports(formData, enqueueSnackbar));
                } 
                else if (error.response?.status === 404) {
                    enqueueSnackbar("Oops! There's no data to download for the chosen duration. Please try a different range...!", {
                        variant: 'info',
                        horizontal: 'center',
                    });
                }else if (error.response?.status >= 500) {
                    enqueueSnackbar("Something went wrong..! Please Try Again later", {
                        variant: 'error',
                        horizontal: 'center',
                    });
                    dispatch({
                        type: GET_DOWNLOAD_REPORTS_FAIL,
                        payload: true,
                    });
                }
            });
    };
};

export const getDipLogs = (formData, fetchMoreData) => {

    if (abortsignal) {
        abortsignal.abort();
    }

    abortsignal = new AbortController();
    const signal = abortsignal.signal;

    return (dispatch) => {
        if (!fetchMoreData) {
            dispatch({
                type: ASSET_LOADING,
            });
        }
        reposWebApi.get("/tracker/get_reconciliation", {
            headers: {
                Authorization: "Bearer " + secureLocalStorage.getItem("access_token"),
            },
            params: formData,
        })
            .then((response) => {
                dispatch({
                    type:  GET_DIP_LOG,
                    payload: response?.data?.data,
                });
            })
            .catch(async (error) => {
                dispatch({
                    type: GET_ASSETS_FAIL,
                });
                if (error?.response?.status === 401) {
                    await dispatch(refreshToken());
                    dispatch(getDipLogs());
                } 
            });
    };
};



